import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { showToast } from '../ui/uiSlice';
import { markAsReadAll, markAsReadSingle } from './notificationsAPI';

export type NotificationType = {
  id: number;
  message: string;
  date: string;
  customerId: string;
  title: string;
  firstName: string;
  lastName: string;
  status: string | null;
  stage: string;
  isRead: boolean;
};

export interface NotificationState {
  notifications: NotificationType[] | [];
  isEstablishingConnection: boolean;
  isConnected: boolean;
  isNew: boolean;
  isLoading: boolean;
  isSuccess: boolean;
}

const initialState: NotificationState = {
  isConnected: false,
  isEstablishingConnection: false,
  isNew: false,
  isLoading: false,
  isSuccess: false,
  notifications: [],
};

const slice = createSlice({
  name: 'notification',
  initialState: initialState,
  reducers: {
    setSuccess: (state, { payload }) => {
      state.isSuccess = payload;
    },
    startConnecting: (state) => {
      state.isEstablishingConnection = true;
    },
    connectionEstablished: (state) => {
      state.isConnected = true;
      state.isEstablishingConnection = true;
    },
    receiveNotifications: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.isNew = action.payload.some((notification: NotificationType) => !notification.isRead);
        state.notifications = action.payload;
      } else {
        state.isNew = true;
        state.notifications = [action.payload, ...state.notifications];
      }
    },
  },
  extraReducers: (builder) => {
    builder.addCase(markNotificationAsRead.fulfilled, (state, { payload }) => {
      const notifications = state.notifications.map((n: NotificationType) => {
        if (n.id === payload.id) {
          return { ...n, isRead: true };
        }
        return n;
      });
      state.notifications = notifications;
      state.isNew = notifications.some((notification: NotificationType) => !notification.isRead);
    });
    builder.addCase(markNotificationsAsRead.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(markNotificationsAsRead.fulfilled, (state, { payload }) => {
      state.notifications = payload;
      state.isLoading = false;
      state.isSuccess = true;
      state.isNew = false;
    });
    builder.addCase(markNotificationsAsRead.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const markNotificationAsRead = createAsyncThunk(
  'notifications/markNotificationAsRead',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      const response = await markAsReadSingle(id);
      return response.data;
    } catch (err: any) {
      dispatch(showToast({ message: err.response.data.message, severity: 'failed' }));
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const markNotificationsAsRead = createAsyncThunk(
  'notifications/markNotificationsAsRead',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await markAsReadAll();
      return response.data;
    } catch (err: any) {
      dispatch(showToast({ message: err.response.data.message, severity: 'failed' }));
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const { startConnecting, setSuccess, receiveNotifications, connectionEstablished } =
  slice.actions;
export const notificationReducer = slice.reducer;
