import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  assignCustomerAPI,
  changeStatusAPI,
  // doNotLendCustomerAPI,
  // notTakenUpAPI,
  payoutCustomerAPI,
  shareCustomerAPI,
} from './commandsAPI';
import { showToast } from '../ui/uiSlice';
import { setStatus } from '../customers/customersSlice';
import { RootState } from '../../store';

type StateType = {
  isLoading: boolean;
  isModalLoading: boolean;
  isModalSuccess: boolean;
  isRequestFinished: boolean;
  error: null | string;
};

export const commandsSlice = createSlice({
  name: 'commands',
  initialState: {
    isLoading: false,
    isModalSuccess: false,
    isModalLoading: false,
    isRequestFinished: false,
    error: null,
  } as StateType,
  reducers: {
    setRequestIsFinished: (state, { payload }) => {
      state.isRequestFinished = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(setPayout.pending, (state) => {
      state.isModalLoading = true;
      state.isModalSuccess = false;
      state.isRequestFinished = false;
    });
    builder.addCase(setPayout.fulfilled, (state) => {
      state.isModalLoading = false;
      state.isModalSuccess = true;
      state.isRequestFinished = true;
    });
    builder.addCase(setPayout.rejected, (state) => {
      state.isModalLoading = false;
      state.isRequestFinished = true;
    });

    builder.addCase(setStatusChange.pending, (state) => {
      state.isModalLoading = true;
      state.isModalSuccess = false;
      state.isRequestFinished = false;
    });
    builder.addCase(setStatusChange.fulfilled, (state) => {
      state.isModalLoading = false;
      state.isModalSuccess = true;
      state.isRequestFinished = true;
    });
    builder.addCase(setStatusChange.rejected, (state) => {
      state.isModalLoading = false;
      state.isRequestFinished = true;
    });

    // builder.addCase(setNotTakenUp.pending, (state) => {
    //   state.isModalLoading = true;
    //   state.isModalSuccess = false;
    // });
    // builder.addCase(setNotTakenUp.fulfilled, (state) => {
    //   state.isModalLoading = false;
    //   state.isModalSuccess = true;
    // });
    // builder.addCase(setNotTakenUp.rejected, (state) => {
    //   state.isModalLoading = false;
    // });

    // builder.addCase(setDoNotLend.pending, (state) => {
    //   state.isModalLoading = true;
    //   state.isModalSuccess = false;
    // });
    // builder.addCase(setDoNotLend.fulfilled, (state) => {
    //   state.isModalLoading = false;
    //   state.isModalSuccess = true;
    // });
    // builder.addCase(setDoNotLend.rejected, (state) => {
    //   state.isModalLoading = false;
    // });

    builder.addCase(assignCustomer.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(assignCustomer.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(assignCustomer.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(shareCustomer.pending, (state) => {
      state.isModalSuccess = false;
      state.isLoading = true;
    });
    builder.addCase(shareCustomer.fulfilled, (state) => {
      state.isModalSuccess = true;
      state.isLoading = false;
    });
    builder.addCase(shareCustomer.rejected, (state) => {
      state.isModalSuccess = false;
      state.isLoading = false;
    });
  },
});
export const setPayout = createAsyncThunk(
  'commands/payout',
  async (data: { password: string }, { dispatch, getState, rejectWithValue }) => {
    try {
      const id = (getState() as RootState).customers.customer.id;
      const response: any = await payoutCustomerAPI(id, data);
      dispatch(setStatus(response.data.details));
      return response.data;
    } catch (err: any) {
      debugger
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const setStatusChange = createAsyncThunk(
  'commands/decline',
  async (data: { password: string; criteria?: string, status?: string }, { dispatch, getState, rejectWithValue }) => {
    try {
      const id = (getState() as RootState).customers.customer.id;
      const response:any = await changeStatusAPI(id, data);
      debugger
      dispatch(setStatus(response.data.details));
      return response.data;
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
      return rejectWithValue(err.response.data.message);
    }
  }
);
// export const setNotTakenUp = createAsyncThunk(
//   'commands/notTakenUp',
//   async (data: { password: string }, { dispatch, getState, rejectWithValue }) => {
//     try {
//       const id = (getState() as RootState).customers.customer.id;
//       const response = await notTakenUpAPI(id, data);
//       dispatch(setStatus(response.data));
//       return response.data;
//     } catch (err: any) {
//       dispatch(
//         showToast({
//           message: err.response.data.message,
//           severity: 'failed',
//         })
//       );
//       return rejectWithValue(err.response.data.message);
//     }
//   }
// );
// export const setDoNotLend = createAsyncThunk(
//     'commands/doNotLend',
//     async (data: { password: string }, { dispatch, getState, rejectWithValue }) => {
//       try {
//         const id = (getState() as RootState).customers.customer.id;
//         const response = await doNotLendCustomerAPI(id, data);
//         dispatch(setStatus(response.data));
//         return response.data;
//       } catch (err: any) {
//         dispatch(
//             showToast({
//               message: err.response.data.message,
//               severity: 'failed',
//             })
//         );
//         return rejectWithValue(err.response.data.message);
//       }
//     }
// );
export const assignCustomer = createAsyncThunk(
  'commands/assignCustomer',
  async (toAdminId: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const id = (getState() as RootState).customers.customer.id;
      const response = await assignCustomerAPI(id, toAdminId);
      // dispatch(setStatus(response.data));
      dispatch(
        showToast({
          message: 'Customer assigned successfully!',
          severity: 'success',
        })
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const shareCustomer = createAsyncThunk(
  'commands/shareCustomer',
  async (withAdminId: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const id = (getState() as RootState).customers.customer.id;
      const response = await shareCustomerAPI(id, withAdminId);
      dispatch(
        showToast({
          message: 'Customer shared successfully!',
          severity: 'success',
        })
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const { setRequestIsFinished } = commandsSlice.actions;

export const commandsReducer = commandsSlice.reducer;
