import { useState } from 'react';
import { useAppDispatch } from '../app/hooks';
import { fetchAddressesAPI } from '../app/features/address/addressAPI';
import { showToast } from '../app/features/ui/uiSlice';

type AddressResponseType = {
  [key: string]: string;
};

const useAddressFetch = () => {
  const [currentAddresses, setCurrentAddresses] = useState<AddressResponseType[] | []>([]);
  const [previousAddresses, setPreviousAddresses] = useState<AddressResponseType[] | []>([]);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useAppDispatch();

  const clearState = () => {
    setCurrentAddresses([]);
    setPreviousAddresses([]);
  };
  const fetchAddresses = async (postCode: string, isPrevious: boolean = false) => {
    setIsLoading(true);
    try {
      const { data } = await fetchAddressesAPI(postCode);
      if (data.status !== 'success') {
        dispatch(
          showToast({
            message: 'No address found for that postcode.',
            severity: 'failed',
          })
        );
        isPrevious ? setPreviousAddresses([]) : setCurrentAddresses([]);
      } else {
        isPrevious ? setPreviousAddresses(data.result) : setCurrentAddresses(data.result);
      }
    } catch (error) {
      dispatch(
        showToast({
          message: 'Error when fetching addresses',
          severity: 'failed',
        })
      );
    } finally {
      setIsLoading(false);
    }
  };

  return { currentAddresses, previousAddresses, isLoading, clearState, fetchAddresses };
};

export default useAddressFetch;
