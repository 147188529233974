import React from 'react';
import closeIcon from '../../assets/close.svg';
import './Searchbar.scss';
import { searchResultType } from '../Navbar/Navbar';

export interface SearchbarComponentProps {
  onClick: () => void;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClose: () => void;
  showMenu: boolean;
  value: string;
  showResults: boolean;
  data: searchResultType[];
}

const Searchbar = ({
  onClick,
  onChange,
  onClose,
  value,
}: React.PropsWithChildren<SearchbarComponentProps>) => {
  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <div>
      <div className="search">
        <form id="form">
          <input
            value={value}
            autoFocus={true}
            onKeyDown={handleKeyDown}
            onClick={onClick}
            type="search"
            placeholder="Search Fuse"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => onChange(e)}
          />
        </form>
        <button onClick={onClose}>
          <img src={closeIcon} alt="close-icon" />
        </button>
      </div>
    </div>
  );
};

export default Searchbar;
