import React from 'react';
import { Link } from 'react-router-dom';
import './CustomLink.scss';

type CustomLinkPropsType = {
  to: string;
  onClick?: () => void;
};

const CustomLink = ({ children, to, onClick }: React.PropsWithChildren<CustomLinkPropsType>) => {
  return (
    <Link className="custom-link" target={'_blank'} to={to} onClick={onClick}>
      {children}
    </Link>
  );
};

export default CustomLink;
