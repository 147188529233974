import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCreditAPI, getSanctionsAPI, refreshAffordabilityAPI } from './searchesAPI';

import { showToast } from '../ui/uiSlice';
import { setAffordability, setCredit, setSanctions, setStatus } from '../customers/customersSlice';
import { RootState } from '../../store';

type StateType = {
  isLoading: boolean;
  isModalLoading: boolean;
  isModalSuccess: boolean;
  isRequestFinished: boolean;
  error: null | string;
};

export const searchesSlice = createSlice({
  name: 'commands',
  initialState: {
    isLoading: false,
    isModalSuccess: false,
    isModalLoading: false,
    isRequestFinished: false,
    error: null,
  } as StateType,
  reducers: {
    setRequestIsFinished: (state, { payload }) => {
      state.isRequestFinished = payload;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(runCredit.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(runCredit.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(runCredit.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(runAffordability.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(runAffordability.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(runAffordability.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(runSanctions.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(runSanctions.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(runSanctions.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const runSanctions = createAsyncThunk(
  'commands/sanctions',
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const id = (getState() as RootState).customers.customer.id;
      const response = await getSanctionsAPI(id);
      dispatch(setSanctions(response.data));
      dispatch(
          showToast({
            message: 'Sanctions check done!',
            severity: 'success',
          })
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const runCredit = createAsyncThunk(
  'commands/credit',
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const id = (getState() as RootState).customers.customer.id;
      const response = await getCreditAPI(id);
      dispatch(setCredit(response.data));
      dispatch(
          showToast({
            message: 'Credit check done!',
            severity: 'success',
          })
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const runAffordability = createAsyncThunk(
  'commands/affordability',
  async (data, { dispatch, getState, rejectWithValue }) => {
    try {
      const id = (getState() as RootState).customers.customer.id;
      const response = await refreshAffordabilityAPI(id);
      dispatch(setAffordability(response.data));
      dispatch(
          showToast({
            message: 'Affordability refreshed',
            severity: 'success',
          })
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const { setRequestIsFinished } = searchesSlice.actions;

export const searchesReducer = searchesSlice.reducer;
