import React from 'react';
import CardContainer from '../../CardContainer/CardContainer';
import Indicator, { IndicatorStatus } from '../../Indicator/Indicator';
import cx from 'classnames';

type ReportType = {
  result: boolean;
};

interface IProps {
  onClick?: () => void;
  className: string;
  report: any;
  display?: 'full' | 'combined' | 'limited';
}

const VerificationCard = ({ onClick, report, className, display = 'combined' }: IProps) => {
  const MAX_ITEMS_PER_LIMITED_VIEW = 6;

  const isDataExist = !!report;

  const setIndicator = (value: boolean) => {
    return value ? IndicatorStatus.SUCCESS : IndicatorStatus.FAIL;
  };

  const status = setIndicator(report?.result);

  const sanctions = {
    ['ID validation']: !report?.details.numprimarychecks,
    ['Individual match']: !report?.details.matchlevel,
    ['Deceased']: !report?.details.deceasedwarning,
    ['Specially designated national']: !report?.details.sdnwarning,
    ['Politically exposed person']: !report?.details.pepwarning,
    ['Sanctions watchlist']: !report?.details.sanctionswarning,
    ['Address validation']: !report?.details.addresslinkswarning,
  };

  return (
    <CardContainer
      {...(isDataExist && { onClick: onClick, status })}
      title={'Verification report'}
      comment={'Data validation'}
      className={cx(className, {
        limited: display === 'limited',
      })}
    >
      {isDataExist && display !== 'limited' && (
        <>
          {Object.entries(sanctions).map(([key, value], idx) => {
            if (display !== 'full' && idx === MAX_ITEMS_PER_LIMITED_VIEW) {
              return;
            }
            return (
              <div className="card__flex">
                <p className="card__text">{key}</p>
                <Indicator status={setIndicator(value)} />
              </div>
            );
          })}
        </>
      )}
    </CardContainer>
  );
};

export default VerificationCard;
