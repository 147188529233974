import React from 'react';
import './CustomersTable.scss';
import CustomLink from '../CustomLink/CustomLink';
import Loader from '../Loader/Loader';
import { format } from 'date-fns';
import { formatMoney } from '../../utils/formatMoney';
import moment from 'moment/moment';

interface ICustomersTable {
  customers: any[];
  isLoading?: boolean;
}

const CustomersTable = ({ customers, isLoading }: ICustomersTable) => {
  return (
    <table className={'customers__table'}>
      <thead>
        <tr>
          <th>Name</th>
          <th>DOB</th>
          <th>Address</th>
          <th>Post code</th>
          <th>Lead date</th>
          <th>Agreement</th>
          <th>Total amount</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        {isLoading && <Loader />}
        {!!customers.length &&
          customers.map((elem: any, idx) => {
            return (
              <tr key={idx}>
                <td>
                  <CustomLink to={`/customers/${elem.customerId}`}>
                    {elem.firstName} {elem.lastName}
                  </CustomLink>
                </td>
                <td>{moment(elem.dob).format('DD/MM/YYYY')}</td>
                <td>{elem.addressFull}</td>
                <td>{elem.postCode}</td>
                <td>{format(new Date(elem.createdAt), 'dd/MM/y')}</td>
                <td>{elem.agreementNumber}</td>
                <td>{formatMoney(elem.totalAmount)}</td>
                <td>{elem.status}</td>
              </tr>
            );
          })}
      </tbody>
    </table>
  );
};

export default CustomersTable;
