import CustomAxios from '../../customAxios/CustomAxios';

export const getLeadsAPI = (startDate: string, endDate: string) => {
  return CustomAxios.get(
    `${process.env.REACT_APP_STATS_URL}/leads?startDate=${startDate}&endDate=${endDate}`
  );
};
export const getAppsAPI = (startDate: string, endDate: string) => {
  return CustomAxios.get(
    `${process.env.REACT_APP_STATS_URL}/apps?startDate=${startDate}&endDate=${endDate}`
  );
};
export const getIntroducedAPI = (startDate: string, endDate: string) => {
  return CustomAxios.get(
    `${process.env.REACT_APP_STATS_URL}/introduced?startDate=${startDate}&endDate=${endDate}`
  );
};
export const getSuccessAPI = (startDate: string, endDate: string) => {
  return CustomAxios.get(
    `${process.env.REACT_APP_STATS_URL}/success?startDate=${startDate}&endDate=${endDate}`
  );
};
export const getPaidAPI = (startDate: string, endDate: string) => {
  return CustomAxios.get(
    `${process.env.REACT_APP_STATS_URL}/paid?startDate=${startDate}&endDate=${endDate}`
  );
};
export const getFundedAPI = (startDate: string, endDate: string) => {
  return CustomAxios.get(
    `${process.env.REACT_APP_STATS_URL}/funded?startDate=${startDate}&endDate=${endDate}`
  );
};

export const getChartDataAPI = (startDate: string, endDate: string) => {
  return CustomAxios.get(
    `${process.env.REACT_APP_STATS_URL}/stats?startDate=${startDate}&endDate=${endDate}`
  );
};
export const getJourneyDataAPI = (startDate: string, endDate: string) => {
  return CustomAxios.get(
    `${process.env.REACT_APP_STATS_URL}/journey?startDate=${startDate}&endDate=${endDate}`
  );
};




export const getReportsAPI = (reportType: string, startDate: string, endDate: string) => {
  return CustomAxios.get(
    `${process.env.REACT_APP_STATS_URL}/dashboard/reports?stage=${reportType}&startDate=${startDate}&endDate=${endDate}`
  );
};
