import React from 'react';
import Title from '../../components/Title/Title';
import WarningTemplateModal from '../../components/DialogModals/WarningTemplateModal/WarningTemplateModal';
import TemplatesForm from './TemplateForm';
import './TemplatesPage.scss';

const TemplatePage = ({ className }: any) => {
  const [showModal, setShowModal] = React.useState<boolean>(false);
  const [selectedMediaOption, setSelectedMediaOption] = React.useState<'sms' | 'email' | null>(
    null
  );
  const [selectedOption, setSelectedOption] = React.useState<number | null>(null);

  return (
    <div className={`templates ${className}`}>
      <Title text={'Templates.'} />
      <TemplatesForm
        setShowModal={setShowModal}
        selectedMediaOption={selectedMediaOption}
        selectedOption={selectedOption}
        setSelectedMediaOption={setSelectedMediaOption}
        setSelectedOption={setSelectedOption}
      />
      <WarningTemplateModal
        visible={showModal}
        type={selectedMediaOption}
        id={selectedOption}
        onClose={() => setShowModal(false)}
      />
    </div>
  );
};

export default TemplatePage;
