import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import {
  createEmailTemplateAPI,
  getAllEmailsAPI,
  getAllSmsAPI,
  createSmsTemplateAPI,
  deleteEmailTemplateAPI,
  deleteSmsTemplateAPI,
  updateSmsTemplateAPI,
  updateEmailTemplateAPI,
} from './templatesAPI';
import { showToast } from '../ui/uiSlice';

export type EmailTemplateType = {
  id: number;
  name: string;
  htmlContent: string;
};
export type SmsTemplateType = {
  id: number;
  name: string;
  content: string;
};

type InitialStateType = {
  isLoading: boolean;
  isSuccess: boolean;
  isDeleteInProgress: boolean;
  isDeleteSuccess: boolean;
  isDeleteFinished: boolean;
  emailList: EmailTemplateType[];
  smsList: SmsTemplateType[];
  error: string | null;
};

const initialState: InitialStateType = {
  isLoading: false,
  isSuccess: false,
  isDeleteSuccess: false,
  isDeleteFinished: false,
  isDeleteInProgress: false,
  emailList: [],
  smsList: [],
  error: null,
};

export const templatesSlice = createSlice({
  name: 'templates',
  initialState,
  reducers: {
    setDeleteFinished: (state, action: PayloadAction<boolean>) => {
      state.isDeleteFinished = action.payload;
    },
    setIsDeleteSuccess: (state, action: PayloadAction<boolean>) => {
      state.isDeleteSuccess = action.payload;
    },
    setSuccess: (state, action: PayloadAction<boolean>) => {
      state.isSuccess = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateSmsTemplate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateSmsTemplate.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.smsList = state.smsList.map((elem: SmsTemplateType) => {
        return elem.id === payload.id ? { ...payload } : elem;
      });
    });
    builder.addCase(updateSmsTemplate.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(updateEmailTemplate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateEmailTemplate.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.emailList = state.emailList.map((elem: EmailTemplateType) => {
        return elem.id === payload.id ? { ...payload } : elem;
      });
    });
    builder.addCase(updateEmailTemplate.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(createEmailTemplate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createEmailTemplate.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.emailList.push(payload);
      state.isSuccess = true;
    });
    builder.addCase(createEmailTemplate.rejected, (state, { payload }) => {
      state.isLoading = false;
    });

    builder.addCase(createSmsTemplate.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createSmsTemplate.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.smsList.push(payload);
      state.isSuccess = true;
    });
    builder.addCase(createSmsTemplate.rejected, (state, { payload }) => {
      state.isLoading = false;
    });

    builder.addCase(getAllEmails.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllEmails.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.emailList = payload;
    });
    builder.addCase(getAllEmails.rejected, (state, { payload }) => {
      state.isLoading = false;
    });

    builder.addCase(getAllSms.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllSms.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.smsList = payload;
    });
    builder.addCase(getAllSms.rejected, (state, { payload }) => {
      state.isLoading = false;
    });

    builder.addCase(deleteEmailTemplate.pending, (state) => {
      state.isDeleteInProgress = true;
      state.isDeleteSuccess = false;
      state.isDeleteFinished = false;
    });
    builder.addCase(
      deleteEmailTemplate.fulfilled,
      (state, { payload }: PayloadAction<{ id: number }>) => {
        state.isDeleteInProgress = false;
        state.emailList = state.emailList.filter(
          (elem: EmailTemplateType) => elem.id !== payload.id
        );
        state.isDeleteFinished = true;
        state.isDeleteSuccess = true;
      }
    );
    builder.addCase(deleteEmailTemplate.rejected, (state, action: any) => {
      state.error = action.payload;
      state.isDeleteFinished = true;
      state.isDeleteInProgress = false;
    });

    builder.addCase(deleteSmsTemplate.pending, (state) => {
      state.isDeleteInProgress = true;
      state.isDeleteSuccess = false;
      state.isDeleteFinished = false;
    });
    builder.addCase(
      deleteSmsTemplate.fulfilled,
      (state, { payload }: PayloadAction<{ id: number }>) => {
        state.isDeleteInProgress = false;
        state.smsList = state.smsList.filter((elem: SmsTemplateType) => elem.id !== payload.id);
        state.isDeleteFinished = true;
        state.isDeleteSuccess = true;
      }
    );
    builder.addCase(deleteSmsTemplate.rejected, (state, action: any) => {
      state.error = action.payload;
      state.isDeleteFinished = true;
      state.isDeleteInProgress = false;
    });
  },
});

export const createEmailTemplate = createAsyncThunk(
  'templates/createEmail',
  async (data: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await createEmailTemplateAPI(data);
      dispatch(
        showToast({
          message: 'Email template successfully created',
          severity: 'success',
        })
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const deleteEmailTemplate = createAsyncThunk(
  'templates/deleteEmail',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      await deleteEmailTemplateAPI(id);
      dispatch(
        showToast({
          message: 'Email template successfully deleted',
          severity: 'success',
        })
      );
      return { id };
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const deleteSmsTemplate = createAsyncThunk(
  'templates/deleteSms',
  async (id: number, { dispatch, rejectWithValue }) => {
    try {
      await deleteSmsTemplateAPI(id);

      dispatch(
        showToast({
          message: 'Sms template successfully deleted',
          severity: 'success',
        })
      );

      return { id };
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const createSmsTemplate = createAsyncThunk(
  'templates/createSms',
  async (data: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await createSmsTemplateAPI(data);
      dispatch(
        showToast({
          message: 'Sms template successfully created',
          severity: 'success',
        })
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const updateSmsTemplate = createAsyncThunk(
  'templates/updateSms',
  async (data: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await updateSmsTemplateAPI(data);
      dispatch(
        showToast({
          message: 'Sms template successfully updated',
          severity: 'success',
        })
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const updateEmailTemplate = createAsyncThunk(
  'templates/updateEmail',
  async (data: any, { dispatch, rejectWithValue }) => {
    try {

      const response = await updateEmailTemplateAPI(data);
      dispatch(
        showToast({
          message: 'Email template successfully created',
          severity: 'success',
        })
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const getAllEmails = createAsyncThunk(
  'templates/getAllEmails',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await getAllEmailsAPI();
      return response.data;
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const getAllSms = createAsyncThunk(
  'templates/getAllSms',
  async (_, { dispatch, rejectWithValue }) => {
    try {
      const response = await getAllSmsAPI();
      return response.data;
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const { setIsDeleteSuccess, setSuccess } = templatesSlice.actions;

export const templatesReducer = templatesSlice.reducer;
