import React from 'react';
import './FakeDropdown.scss';

type FakeDropdownPropsType = {
  label: string;
  onClick: () => void;
};

const FakeDropdown = ({
  children,
  label,
  onClick,
}: React.PropsWithChildren<FakeDropdownPropsType>) => {
  return (
    <div className="fake-dropdown">
      <label className="fake-dropdown__label">{label}</label>
      <button className="fake-dropdown__btn" onClick={onClick}>
        {children}
      </button>
    </div>
  );
};

export default FakeDropdown;
