import React from 'react';
import './Menu.scss';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';

import {
  runAffordability,
  runCredit,
  runSanctions,
} from '../../../app/features/searches/searchesSlice';

import MenuItem from './MenuItem';
import { useMatch, useParams } from 'react-router-dom';

export type SubmenuType = {
  title: string;
  url?: string;
  action?: any;
  id?: string;
};

export type MenuItemType = {
  title: string;
  action?: any;
  id?: string;
  submenu?: SubmenuType[];
};

type MenuPropsType = {
  handleChangeProfileView: () => void;
  setShowModal: (id: number) => void;
  agentView: boolean;
};

const Menu = ({ setShowModal, agentView, handleChangeProfileView }: MenuPropsType) => {
  let profile = useMatch({ path: 'customers/:id', end: true });

  const { id } = useParams();

  const { isLoading } = useAppSelector((state) => state.searches);

  const dispatch = useAppDispatch();

  const menuItems: MenuItemType[] = [
    {
      title: 'Comms',
      submenu: [
        {
          title: 'SMS',
          url: `${id}/sms`,
        },
        {
          title: 'Email',
          url: `${id}/email`,
        },
      ],
    },
    {
      title: 'Decision',
      submenu: [
        {
          title: 'Pay out',
          id: 'payout',
          action: () => setShowModal(1),
        },
        {
          title: 'Decline',
          id: 'decline',
          action: () => setShowModal(2),
        },
        {
          title: 'Do not lend',
          id: 'doNotLend',
          action: () => setShowModal(3),
        },
        {
          title: 'Not taken up',
          id: 'notTakenUp',
          action: () => setShowModal(4),
        },
      ],
    },
    {
      title: 'Searches',
      submenu: [
        {
          title: 'Credit',
          id: 'credit',
          action: () => dispatch(runCredit()),
        },
        {
          title: 'Affordability',
          id: 'affordability',
          action: () => dispatch(runAffordability()),
        },
        {
          title: 'Verification',
          id: 'verification',
          action: () => dispatch(runSanctions()),
        },
      ],
    },
    {
      title: 'Share',
      action: () => setShowModal(5),
      id: 'share',
    },
  ];

  return (
    <nav className="customer-menu menu">
      <ul className="menu__list">
        {menuItems.map((menu, index) => {
          return <MenuItem isLoading={isLoading} items={menu} key={index} />;
        })}
        <li className="menu__item_round">
          {profile && (
            <button onClick={handleChangeProfileView} className="menu__btn_round">
              {agentView ? 'UW' : 'Agent'}
            </button>
          )}
        </li>
      </ul>
    </nav>
  );
};
export default Menu;
