import React from 'react';
import Modal from '../Modal/Modal';
import VerificationCard from '../profileComponents/VerificationCard/VerificationCard';
import AffordabilityCard from '../profileComponents/AffordabilityCard/AffordabilityCard';
import CreditRiskCard from '../profileComponents/CreditRiskCard/CreditRiskCard';
import AccountScoreCard from '../profileComponents/AccountScoreCard/AccountScoreCard';
import CallReportCard from '../profileComponents/CallReportCard/CallReportCard';
import ApplicantCardModal from '../modals/ApplicantCardModal/ApplicantCardModal';
import AssignModal from '../modals/AssignModal/AssignModal';
import LoanModal from '../modals/LoanModal/LoanModal';
import ApplicantData from '../modals/ApplicantDataModal/ApplicantData';
import EmailChainModal from '../CommsModals/EmailModal/EmailModal';
import SmsChainModal from '../CommsModals/SmsModal/SmsModal';
import moment from 'moment/moment';

type ModalManagerPropsType = {
  reports: any;
  customer: any;
  handleCloseModal: () => void;
  modalId: number | null;
};

const ModalManager = ({
  reports,
  customer,
  handleCloseModal = () => null,
  modalId = null,
}: ModalManagerPropsType) => {
  return (
    <>
      <Modal show={modalId === 1} onClose={handleCloseModal}>
        <VerificationCard display="full" report={reports.sanctions} className={'profile__card'} />
      </Modal>
      <Modal show={modalId === 2} onClose={handleCloseModal}>
        <AffordabilityCard
          display="full"
          className={'profile__card'}
          report={reports.affordability}
        />
      </Modal>
      <Modal show={modalId === 3} onClose={handleCloseModal}>
        <CreditRiskCard
          display="full"
          className={'profile__card'}
          report={reports.credit?.applicantCreditData}
        />
      </Modal>
      <Modal show={modalId === 4} onClose={handleCloseModal}>
        <AccountScoreCard
          display="full"
          className={'profile__card'}
          report={reports.affordability}
        />
      </Modal>
      <Modal show={modalId === 5} onClose={handleCloseModal}>
        <CallReportCard display="full" className={'profile__card'} report={reports.credit} />
      </Modal>
      <Modal show={modalId === 6} onClose={handleCloseModal}>
        <ApplicantCardModal
          title={customer.title}
          firstName={customer.firstName}
          lastName={customer.lastName}
          dob={moment(customer.dob).format('DD/MM/YYYY')}
        />
      </Modal>
      <Modal show={modalId === 7} onClose={handleCloseModal}>
        <AssignModal assignedUser={customer.application.assignedTo} onClose={handleCloseModal} />
      </Modal>
      <Modal show={modalId === 8} onClose={handleCloseModal}>
        <LoanModal
          totalAmount={customer.application?.totalAmount}
          monthlyRepayment={customer.application?.monthlyRepayment}
          term={customer.application?.term}
          monthlyPaymentDueDay={customer.application?.hire?.monthlyPaymentDueDay}
          firstPaymentDueDate={customer.application?.hire?.firstPaymentDueDate}
        />
      </Modal>
      <Modal show={modalId === 9} onClose={handleCloseModal}>
        <ApplicantData
          emailAddress={customer.emailAddress}
          mobileNumber={customer.mobileNumber}
          currentAddress={customer.addresses?.current}
          previousAddress={customer.addresses?.previous || null}
          bankDetails={customer.bankDetails}
          residentialStatus={customer.residentialStatus}
        />
      </Modal>
      <EmailChainModal visible={modalId === 10} onClose={handleCloseModal} />
      <SmsChainModal visible={modalId === 11} onClose={handleCloseModal} />
    </>
  );
};

export default ModalManager;
