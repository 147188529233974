import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { addUserAPI, deleteUserAPI, getUsersAPI, resetPasswordAPI } from './usersAPI';
import { showToast } from '../ui/uiSlice';

type UserType = {
  fullName: string;
  emailAddress: string;
  id: string;
  password: string;
};

type InitialStateType = {
  isLoading: boolean;
  isSuccess: boolean;
  isResetSuccess: boolean;
  isDeleteInProgress: boolean;
  isDeleteSuccess: boolean;
  isDeleteFinished: boolean;
  users: Array<UserType>;
  error: string | null;
};

const initialState: InitialStateType = {
  isLoading: false,
  isSuccess: false,
  isResetSuccess: false,
  isDeleteSuccess: false,
  isDeleteFinished: false,
  isDeleteInProgress: false,
  users: [],
  error: null,
};

export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setDeleteFinished: (state, action: PayloadAction<boolean>) => {
      state.isDeleteFinished = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUsers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getUsers.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.users = payload.map((elem: UserType) => {
        return { ...elem, password: '' };
      });
    });
    builder.addCase(getUsers.rejected, (state, { payload }) => {
      state.isLoading = false;
    });

    builder.addCase(addUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(addUser.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.users.push(payload);
      state.isSuccess = true;
    });
    builder.addCase(addUser.rejected, (state, { payload }) => {
      state.isLoading = false;
    });

    builder.addCase(deleteUser.pending, (state) => {
      state.isDeleteInProgress = true;
    });
    builder.addCase(deleteUser.fulfilled, (state, { payload }) => {
      state.isDeleteInProgress = false;
      state.users = state.users.filter((elem) => elem.id !== payload);
      state.isDeleteFinished = true;
      state.isDeleteSuccess = true;
    });
    builder.addCase(deleteUser.rejected, (state, action: any) => {
      state.error = action.payload;
      state.isLoading = false;
      state.isDeleteInProgress = false;
    });

    builder.addCase(resetPassword.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(resetPassword.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.isResetSuccess = true;
      state.users = state.users.map((u) =>
        u.id === payload.id ? { ...u, password: payload.password } : u
      );
    });
    builder.addCase(resetPassword.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const getUsers = createAsyncThunk('users/getUsers', async (_, { dispatch }) => {
  try {
    const response = await getUsersAPI();
    return response.data;
  } catch (err: any) {
    dispatch(
      showToast({
        message: err.response.data.message,
        severity: 'failed',
      })
    );
  }
});

export const addUser = createAsyncThunk('users/addUser', async (data: any, { dispatch }) => {
  try {
    const response = await addUserAPI(data);
    return response.data;
  } catch (err: any) {
    dispatch(
      showToast({
        message: err.response.data.message,
        severity: 'failed',
      })
    );
  }
});

export const deleteUser = createAsyncThunk(
  'users/deleteUser',
  async (id: string, { rejectWithValue }) => {
    try {
      await deleteUserAPI(id);
      return id;
    } catch (err: any) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const resetPassword = createAsyncThunk(
  'users/resetPassword',
  async (id: string, { dispatch }) => {
    try {
      const response = await resetPasswordAPI(id);
      return { ...response.data, id: id };
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
    }
  }
);
export const { setDeleteFinished } = usersSlice.actions;

export default usersSlice.reducer;
