import React from 'react';
import styles from './InputWithBtn.module.scss';
import cx from 'classnames';
import InputMask from 'react-input-mask';
import Button from '../Button/Button';

interface IInput {
  value: string;
  type: string;
  name: string;
  id: string;
  placeholder?: string;
  onClick: (value: string) => void;
  onChange: (value: string) => void;
  error: string;
  className: string;
  label?: string;
  children: any;
  mask?: any;
}

const InputWithBtn = ({
  id,
  name,
  type,
  value,
  placeholder,
  error,
  className,
  label,
  onClick,
  children,
  onChange,
  ...restInputProps
}: any) => {
  return (
    <div className={`${styles.input} ${className}`}>
      {label && <label htmlFor={name}>{label}</label>}
      <div className={styles.wrapper}>
        <InputMask
          type={type}
          name={name}
          id={id}
          value={value}
          placeholder={placeholder}
          onChange={(e: any) => onChange(e.currentTarget.value)}
          className={cx({
            error: error,
          })}
          {...restInputProps}
        />
        <Button onClick={() => onClick(value)} className={styles.btn}>
          {children}
        </Button>
      </div>
      {error && <span className={styles.errorText}>{error}</span>}
    </div>
  );
};

export default InputWithBtn;
