import CustomAxios from '../../customAxios/CustomAxios';

export const searchCustomersAPI = (searchValue: string) => {
  return CustomAxios.get(`${process.env.REACT_APP_CUSTOMERS_URL}/customers?search=${searchValue}`);
};
export const getCustomerByIdAPI = (customerId: string) => {
  return CustomAxios.get(`${process.env.REACT_APP_CUSTOMERS_URL}/customers/${customerId}`);
};

export const updateProfileAPI = (customerId: string, payload: any) => {
  return CustomAxios.patch(`${process.env.REACT_APP_CUSTOMERS_URL}/customers/${customerId}/profile`, payload);
};
export const updateBankDetailsAPI = (customerId: string, payload: any) => {
  return CustomAxios.put(`${process.env.REACT_APP_CUSTOMERS_URL}/customers/${customerId}/bank`, payload);
};
export const updateAddressAPI = (customerId: string, payload: string) => {
  return CustomAxios.put(`${process.env.REACT_APP_CUSTOMERS_URL}/customers/${customerId}/address`, payload);
};
export const updateHireAPI = (id: string, data: any) => {
  return CustomAxios.put(`${process.env.REACT_APP_CUSTOMERS_URL}/customers/${id}/hire`, data);
};