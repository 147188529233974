import React, { useState } from 'react';
import './CustomerProfile.scss';
import ApplicantInfoCard from '../../components/profileComponents/ApplicantInfoCard/ApplicantInfoCard';
import ApplicationManagementCard from '../../components/profileComponents/ApplicationManagementCard/ApplicationManagementCard';
import LoanInfoCard from '../../components/profileComponents/LoanInfoCard/LoanInfoCard';
import DetailsInfoCard from '../../components/profileComponents/DetailsInfoCard/DetailsInfoCard';
import VerificationCard from '../../components/profileComponents/VerificationCard/VerificationCard';
import AffordabilityCard from '../../components/profileComponents/AffordabilityCard/AffordabilityCard';
import CreditRiskCard from '../../components/profileComponents/CreditRiskCard/CreditRiskCard';
import AccountScoreCard from '../../components/profileComponents/AccountScoreCard/AccountScoreCard';
import CallReportCard from '../../components/profileComponents/CallReportCard/CallReportCard';
import ModalManager from '../../components/ModalManager/ModalManager';
import { useOutletContext } from 'react-router-dom';
import moment from 'moment';

const CustomerProfile = () => {
  const [customer, reports, agentView] = useOutletContext() as any;

  const [openModalId, setOpenModalId] = useState(null);
  const handleOpenModal = (id: any) => setOpenModalId(id);
  const handleCloseModal = () => setOpenModalId(null);

  if (!customer) return null;

  const { credit, sanctions, affordability } = reports;

  return (
    <div className="profile">
      <div className="profile__body">
        <div className="profile__left-col">
          <ApplicantInfoCard
            className="profile__card"
            prefix={customer.title}
            fullName={`${customer.firstName} ${customer.lastName}`}
            dob={moment(customer.dob).format('DD/MM/YYYY')}
            agreementNumber={customer.application.agreementNumber}
            onClick={() => handleOpenModal(6)}
          />
          <LoanInfoCard
            totalAmount={customer.application.hire.totalAmount}
            term={customer.application.hire.term}
            monthlyRepayment={customer.application.hire.monthlyRepayment}
            furniturePack={customer.application.hire.furniturePack}
            furnitureStyle={customer.application.hire.furnitureStyle}
            onClick={() => handleOpenModal(8)}
            className={'profile__card'}
          />
          <DetailsInfoCard
            mobileNumber={customer.mobileNumber}
            handleEmailClick={() => handleOpenModal(10)}
            handleSMSClick={() => handleOpenModal(11)}
            emailAddress={customer.emailAddress}
            addresses={customer.addresses}
            bankDetails={customer.bankDetails}
            onClick={() => handleOpenModal(9)}
            className={'profile__card'}
          />
        </div>
        <div className={(!agentView && 'profile__right-col') || ''}>
          <ApplicationManagementCard
            assignee={customer.application.assignedTo}
            display={(agentView && 'limited') || 'combined'}
            status={customer.application.status.applicationStatus}
            criteriaStatus={customer.application.status.criteria}
            stage={customer.application.status.stage}
            onClick={() => handleOpenModal(7)}
            className={'profile__card'}
          />
          <VerificationCard
            report={reports.sanctions}
            display={(agentView && 'limited') || 'combined'}
            onClick={() => handleOpenModal(1)}
            className={'profile__card'}
          />
          <AffordabilityCard
            report={reports.affordability}
            display={(agentView && 'limited') || 'combined'}
            onClick={() => handleOpenModal(2)}
            className={'profile__card'}
          />

          <CreditRiskCard
            report={credit?.applicantCreditData}
            display={(agentView && 'limited') || 'combined'}
            onClick={() => handleOpenModal(3)}
            className={'profile__card'}
          />
          <AccountScoreCard
            report={reports.affordability}
            display={(agentView && 'limited') || 'combined'}
            onClick={() => handleOpenModal(4)}
            className={'profile__card'}
          />
          <CallReportCard
            report={credit}
            display={(agentView && 'limited') || 'combined'}
            onClick={() => handleOpenModal(5)}
            className={'profile__card'}
          />
        </div>
      </div>
      <ModalManager
        customer={customer}
        reports={reports}
        handleCloseModal={handleCloseModal}
        modalId={openModalId}
      />
    </div>
  );
};

export default CustomerProfile;
