import React, { useEffect, useState } from 'react';
import ChainedModal from '../../ChainedModal/ChainedModal';
import DialogPopupWrapper from '../../DialogPopup/DialogPopup';
import Button from '../../Button/Button';
import '../CommsModal.scss';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { ISelectOption } from '../../CustomSelect/CustomSelect';
import { useOutletContext } from 'react-router-dom';
import { RootState } from '../../../app/store';
import { sendSms } from '../../../app/features/comms/commsSlice';
import { getAllSms, SmsTemplateType } from '../../../app/features/templates/templatesSlice';
import { formatToSelect } from '../../../utils/formatToSelect';
import SmsContainer from '../../Comms/SmsContainer';

const Modal1 = (props: any) => {
  const [customer] = useOutletContext() as any;
  const editorRef: React.MutableRefObject<any | null> = React.useRef(null);

  const dispatch = useAppDispatch();
  const { smsList } = useAppSelector((state: RootState) => state.templates);
  const [templates, setTemplates] = useState<any[]>([]);
  const [content, setContent] = React.useState('');
  const [contentTouched, setContentTouched] = React.useState(false);

  useEffect(() => {
    if (!smsList.length) dispatch(getAllSms());
  }, []);

  useEffect(() => {
    setTemplates(formatToSelect(smsList));
  }, [smsList]);

  const handleSelectedTemplate = (obj: ISelectOption) => {
    const selectedTemplate = JSON.parse(
      JSON.stringify(smsList.find((t: SmsTemplateType): boolean => t.id === obj.value))
    );
    //replace variable with customer firstName
    selectedTemplate.content = selectedTemplate.content.replace('{{fullName}}', customer.firstName);
    editorRef.current.clearContent();
    editorRef.current.insertContent(selectedTemplate.content);
  };

  const handleSend = () => {
    dispatch(sendSms({ content }));
    props.showNext();
  };

  const handleCancel = () => {
    setContent('');
    setContentTouched(false);
    editorRef.current.clearContent();
  };

  const handleContentChanges = (content: string) => {
    setContent(content as any);
    setContentTouched(true);
  };

  return (
    <div className="comms-modal">
      <SmsContainer
        options={templates}
        setSelectedOption={handleSelectedTemplate}
        handleCancel={handleCancel}
        handleSend={handleSend}
        contentTouched={contentTouched}
        content={content}
        editorRef={editorRef}
        setContent={handleContentChanges}
      />
    </div>
  );
};

const Modal2 = (props: any) => {
  return (
    <DialogPopupWrapper title={'SMS'} className={'modal'}>
      <div className="modal__body">
        <p className="modal__text">Message sent.</p>
        <Button onClick={() => props.closeChainedModal()} className={'modal__btn'}>
          OK
        </Button>
      </div>
    </DialogPopupWrapper>
  );
};

const SmsChainModal = ({ onClose, visible }: any) => {
  return (
    <ChainedModal
      visible={visible}
      onRequestClose={onClose}
      closeOnBackground
      elements={[
        {
          component: Modal1,
        },
        {
          component: Modal2,
        },
      ]}
    />
  );
};

export default SmsChainModal;
