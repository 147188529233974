import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { sendSmsAPI, sendEmailAPI, PayloadSendEmailType, PayloadSendSmsType } from './commsAPI';
import { showToast } from '../ui/uiSlice';
import { RootState } from '../../store';

type StateType = {
  isLoading: boolean;
  isSent: boolean;
  error: null | string;
};

export const communicationsSlice = createSlice({
  name: 'communications',
  initialState: {
    isLoading: false,
    isSent: false,
    error: null,
  } as StateType,
  reducers: {
    setIsSent: (state, payload) => {
      state.isSent = payload.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(sendEmail.pending, (state) => {
      state.isLoading = true;
      state.isSent = false;
    });
    builder.addCase(sendEmail.fulfilled, (state) => {
      state.isSent = true;
      state.isLoading = false;
    });
    builder.addCase(sendEmail.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(sendSms.pending, (state) => {
      state.isLoading = true;
      state.isSent = false;
    });
    builder.addCase(sendSms.fulfilled, (state) => {
      state.isSent = true;
      state.isLoading = false;
    });
    builder.addCase(sendSms.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
export const sendEmail = createAsyncThunk(
  'communications/sendEmail',
  async (data: PayloadSendEmailType, { dispatch, getState, rejectWithValue }) => {
    try {
      const customerId = (getState() as RootState).customers.customer.id;
      const adminId = (getState() as RootState).userReducer.user.id;

      const payload = {
        toId: customerId,
        fromId: adminId,
        body: data.content,
        subject: data.subject,
      };

      const response = await sendEmailAPI(payload);
      dispatch(
        showToast({
          message: 'Email sent',
          severity: 'success',
        })
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const sendSms = createAsyncThunk(
  'communications/sendSms',
  async (data: any, { dispatch, getState, rejectWithValue }) => {

    try {
      const customerId = (getState() as RootState).customers.customer.id;
      const adminId = (getState() as RootState).userReducer.user.id;

      const payload = {
        toId: customerId,
        fromId: adminId,
        message: data.content,
      };


      const response = await sendSmsAPI(payload);
      dispatch(
        showToast({
          message: 'Sms sent',
          severity: 'success',
        })
      );
      return response.data;
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const { setIsSent } = communicationsSlice.actions;

export const communicationsReducer = communicationsSlice.reducer;
