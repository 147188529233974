import React from 'react';
import './Indicator.scss';

export enum IndicatorStatus {
  FAIL = 'red',
  SUCCESS = 'green',
  NO_MATCH = 'grey',
}

type IndicatorType = {
  status: IndicatorStatus;
};

const Indicator = ({ status = IndicatorStatus.NO_MATCH }: IndicatorType) => {
  return <div className={`indicator ${status}`} />;
};

export default Indicator;
