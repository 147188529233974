import React from 'react';
import CardContainer from '../CardContainer/CardContainer';
import './LoanModal.scss';
import BtnGroup from '../../BtnGroup/BtnGroup';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setSuccess, updateHire } from '../../../app/features/customers/customersSlice';
import { Field, Form, Formik } from 'formik';
import cx from 'classnames';
import Input from '../../input/Input';
import Select, { ISelectOption } from '../../CustomSelect/CustomSelect';
import moment from 'moment/moment';

interface ILoanModal {
  monthlyPaymentDueDay: number | null;
  firstPaymentDueDate: string | null;
}

const LoanModal = ({
  monthlyPaymentDueDay,
  firstPaymentDueDate,
}: any) => {
  const { isModalLoading, isSuccess } = useAppSelector((state) => state.customers);
  React.useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        dispatch(setSuccess(false));
      }, 5000);
    }
  }, [isSuccess]);

  const dispatch = useAppDispatch();

  const formikConfig = {
    initialValues: {
      firstPaymentDueDate: moment(firstPaymentDueDate, 'YYYY-MM-DD').format('DD/MM/YYYY'),
      monthlyPaymentDueDay,
    },
    onSubmit: () => {},
  };

  return (
    <CardContainer className="loan-modal" size={'lg'} comment={'Information about the hire'}>
      <div className="loan-modal__container">
        <Formik {...formikConfig}>
          {({
            errors,
            handleChange,
            values,
            touched,
            resetForm,
            initialValues,
            dirty,
            isSubmitting,
            submitForm,
            isValid,
            setFieldValue,
          }) => {
            return (
              <Form className="applicant-data__form form" autoComplete="off">
                <Field
                  label={'First payment date'}
                  id="firstPaymentDueDate"
                  name="firstPaymentDueDate"
                  mask="99/99/9999"
                  placeholder="DD/MM/YYYY"
                  alwaysShowMask={true}
                  onChange={(e: any) => setFieldValue('firstPaymentDueDate', e.currentTarget.value)}
                  error={touched.firstPaymentDueDate && errors.firstPaymentDueDate}
                  className={cx({
                    ['form__field']: true,
                    error: touched.firstPaymentDueDate && errors.firstPaymentDueDate,
                  })}
                  as={Input}
                />
                <Field
                  id="monthlyPaymentDueDay"
                  name="monthlyPaymentDueDay"
                  label={'Default payment due day'}
                  onChange={(e: ISelectOption) => {
                    setFieldValue('monthlyPaymentDueDay', e.value);
                  }}
                  optionsList={Array.from({ length: 31 }, (_, i) => ({
                    label: i + 1,
                    value: i + 1,
                  }))}
                  selectedOption={{
                    label: values.monthlyPaymentDueDay,
                    value: values.monthlyPaymentDueDay,
                  }}
                  className={'form__field'}
                  as={Select}
                />
                <BtnGroup
                  className={'applicant-data__btn-block'}
                  isActive={dirty || isSubmitting}
                  onClick={() => {
                    dispatch(updateHire(values));
                  }}
                  onCancel={() => {
                    resetForm();
                  }}
                  isLoading={isModalLoading}
                  isSuccess={isSuccess}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </CardContainer>
  );
};

export default LoanModal;
