import { ChangeEventHandler, useState } from 'react';
import { DayPicker, DateFormatter, DateRange, SelectRangeEventHandler } from 'react-day-picker';
import { format, isAfter, isBefore, isValid, parse } from 'date-fns';
import Input from '../input/Input';
import Index from '../Button/Button';
import 'react-day-picker/dist/style.css';
import './DatePickerInput.scss';
import arrow from '../../assets/arrow-right.png';
import ranges from '../../helpers/datePickerRanges';

const formatCaption: DateFormatter = (month, options) => {
  return <>{format(month, 'LLLL', { locale: options?.locale })}</>;
};

const today = new Date();
const DatePicker = ({ onClose, handleSelectedDates, date }: any) => {
  const [rangeName, setRangeName] = useState((date && date.rangeName) || ranges[0].name);
  const [selectedRange, setSelectedRange] = useState<DateRange>(
    date && date.from ? { to: date.to, from: date.from } : ranges[0].range
  );
  const [fromValue, setFromValue] = useState<string>(
    (date && format(date.from, 'dd/MM/y')) || format(today, 'dd/MM/y')
  );
  const [toValue, setToValue] = useState<string>(
    (date && format(date.to, 'dd/MM/y')) || format(today, 'dd/MM/y')
  );

  const handleFromChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setFromValue(e.target.value);
    const date = parse(e.target.value, 'dd/MM/y', today);
    if (selectedRange?.to && isAfter(date, selectedRange.to)) {
      setSelectedRange({ from: selectedRange.to, to: date });
    } else {
      setSelectedRange({ from: date, to: selectedRange?.to });
    }
  };
  const handleToChange: ChangeEventHandler<HTMLInputElement> = (e) => {
    setToValue(e.target.value);
    const date = parse(e.target.value, 'dd/MM/y', today);

    if (!isValid(date)) {
      return setSelectedRange({
        from: selectedRange?.from,
        to: undefined,
      });
    }
    if (selectedRange?.from && isBefore(date, selectedRange.from)) {
      setSelectedRange({ from: date, to: selectedRange.from });
    } else {
      setSelectedRange({ from: selectedRange?.from, to: date });
    }
  };
  const handleRangeSelect: SelectRangeEventHandler = (range: DateRange | any) => {
    setSelectedRange(range);
    if (range?.from) {
      setFromValue(format(range.from, 'dd/MM/y'));
    } else {
      setFromValue('');
    }
    if (range?.to) {
      setToValue(format(range.to, 'dd/MM/y'));
    } else {
      setToValue('');
    }
  };

  return (
    <div className="date-picker">
      <ul className="ranges">
        {ranges.map((range) => (
          <li className={range.name === rangeName ? 'active' : ''} key={range.name}>
            <button
              onClick={(e) => {
                handleRangeSelect(range.range, today, null as any, e);
                setRangeName(range.name);
              }}
            >
              {range.name}
            </button>
          </li>
        ))}
      </ul>
      <div className="main-container">
        <DayPicker
          weekStartsOn={1}
          numberOfMonths={2}
          formatters={{ formatCaption }}
          mode="range"
          selected={selectedRange}
          onSelect={handleRangeSelect}
          onDayClick={() => setRangeName('Custom')}
        />
        <div className="bottom-block">
          <div className="input-block">
            <div className="input-wrapper">
              <Input value={fromValue} onChange={handleFromChange} />
            </div>
            <div className="arrow">
              <img src={arrow} alt="" />
            </div>
            <div className="input-wrapper">
              <Input value={toValue} onChange={handleToChange} />
            </div>
          </div>
          <div className="btn-block">
            <Index onClick={onClose}>Cancel</Index>
            <Index
              onClick={() => {
                onClose();
                handleSelectedDates({
                  rangeName,
                  from: selectedRange.from,
                  to: selectedRange.to,
                });
              }}
            >
              Set date
            </Index>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DatePicker;
