import React from 'react';
import './Checkbox.scss';

type CheckboxType = {
  label: string;
  checked?: boolean;
  onChange: (value: boolean) => void;
};

const Checkbox = ({ label, checked, onChange }: CheckboxType) => {
  const defaultChecked = checked ? checked : false;
  const [isChecked, setIsChecked] = React.useState(defaultChecked);

  const handleChecked = () => {
    setIsChecked(!isChecked);
    onChange(!isChecked);
  };

  return (
    <div className={'checkbox'}>
      <label>
        <span className={'checkbox__label'}>{label}</span>
        <input type="checkbox" onChange={handleChecked} checked={isChecked} />
      </label>
    </div>
  );
};

export default Checkbox;
