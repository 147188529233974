import { AxiosError } from 'axios';
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { AuthData } from '../../../pages/LoginPage/LoginPage';
import { signInAPI, getMeAPI, signOutAPI } from './authAPI';
import { setUser } from '../user/userSlice';

type SliceState = {
  isLoading: boolean;
  isAuth: boolean;
  error: null | string;
};

export const authSlice = createSlice({
  name: 'auth',
  initialState: {
    isLoading: false,
    isAuth: false,
    error: null,
  } as SliceState,
  reducers: {
    logOut(state) {
      localStorage.clear();
      return {
        ...state,
        isAuth: false,
        isLoading: false,
        error: null,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getMe.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getMe.fulfilled, (state) => {
        state.isLoading = false;
        state.isAuth = true;
      })
      .addCase(getMe.rejected, (state) => {
        state.isAuth = false;
        state.isLoading = false;
      })
      .addCase(signIn.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(signIn.fulfilled, (state) => {
        state.isLoading = false;
        state.isAuth = true;
        state.error = null;
      })
      .addCase(signIn.rejected, (state, action: PayloadAction<any>) => {
        state.error = action.payload;
        state.isLoading = false;
      })
      .addCase(singOut.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(singOut.fulfilled, (state) => {
        state.isLoading = false;
        state.isAuth = false;
        state.error = null;
      })
      .addCase(singOut.rejected, (state, action: PayloadAction<any>) => {
        state.isLoading = false;
      });
  },
});
export const signIn = createAsyncThunk(
  'auth/signIn',
  async (data: AuthData, { rejectWithValue, dispatch }) => {
    try {
      const response = await signInAPI(data);
      if (response.data.token) {
        localStorage.setItem('token', JSON.stringify(response.data.token));
      }
      dispatch(setUser(response.data.user));
    } catch (err: any) {
      const error = err as AxiosError;
      return rejectWithValue(error.message);
    }
  }
);
export const singOut = createAsyncThunk(
  'auth/signOut',
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await signOutAPI();
      localStorage.clear()
      window.location.href = "/login";
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const getMe = createAsyncThunk('auth/getMe', async (data, { rejectWithValue, dispatch }) => {
  try {
    const response = await getMeAPI();
    dispatch(setUser(response.data));
    return response.data;
  } catch (err: any) {
    return rejectWithValue(err.response.data.message);
  }
});

const { actions, reducer } = authSlice;
export const { logOut } = actions;
export default reducer;
