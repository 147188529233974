import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getCustomerByIdAPI,
  updateAddressAPI,
  updateBankDetailsAPI,
  updateProfileAPI,
  updateHireAPI,
} from './customersAPI';
import { showToast } from '../ui/uiSlice';
import { RootState } from '../../store';
import moment from 'moment';

type AddressType = {
  postCode: string;
  townOrCity: string;
  county: string;
  street: string;
  flatNameOrNumber: string;
  houseOrBuildingName: string;
  houseNumber: string;
  timeAtAddress: string | null;
};

type BankDetailsType = {
  accountNumber: string;
  sortCode: string;
  bankName: string;
  debitCardType: string | null;
  accountHolder: string | null;
};

type FinancialStatusType = {
  activeAccount: boolean;
  income: number | null;
  rent: number | null;
  additionalIncome: number | null;
  bills: number | null;
  bnpl: number | null;
  debt: number | null;
  billsReason: string | null;
  travel: number | null;
  travelMethod: string | null;
  food: number | null;
  foodReason: string | null;
  clothing: number | null;
  takeaways: number | null;
  cashOut: number | null;
  transfersOut: number | null;
  gaming: number | null;
  paydayLoans: number | null;
  expectIncomeToChange: boolean | null;
  expectIncomeToChangeReason: string | null;
  expectedIncome: number | null;
  otherIncome: number | null;
  additionalIncomeIsIncome: boolean;
  cashOutIsBudget: boolean;
  transfersOutIsBudget: boolean;
  totalIncome: number | null;
  totalExpenditures: number | null;
};

type ConsentsType = {
  termsAndConditions: boolean;
  termsAndConditionsTimestamp: string;
  credit: boolean;
  creditTimestamp: string;
  openBanking: boolean;
  openBankingTimestamp: string;
  directDebit: boolean;
  directDebitTimestamp: string;
  sanctions: boolean;
  sanctionsTimestamp: string;
  isAgreementSigned: boolean;
  agreementSignedTimestamp: string;
};

type ApplicationType = {
  id: string;
  agreementNumber: string | null;
  applicationCompletedDate: string | null;
  assignedTo: AssigneeType | null;
  deliveryAddress: string;
  deliveryDate: string;
  deliveryTime: string;
  firstPaymentDueDate: string | null;
  hirePaidDate: string | null;
  hirePaidBackDate: string | null;
  mandateId: string | null;
  monthlyRepayment: number;
  term: number;
  totalAmount: number;
  furnitureStyle: string;
  furniturePack: string;
  financialStatus: FinancialStatusType | null;
  consents: ConsentsType;
  status: {
    currentStageId: string | null;
    stage: string;
    status: string;
    criteria: string | null;
  };
};

export type CustomerType = {
  id: string;
  title: string;
  firstName: string;
  lastName: string;
  dob: string;
  residentialStatus: string;
  bankDetails: BankDetailsType[] | [];
  emailAddress: string;
  mobileNumber: string;
  addresses: {
    current: AddressType;
    previous: AddressType | null;
  };
  application: ApplicationType;
};

export type AssigneeType = {
  userId: string;
  fullName: string;
};

export type ReportType = {
  credit: {} | null;
  sanctions: {} | null;
  affordability: {} | null;
};

export const customersSlice = createSlice({
  name: 'customers',
  initialState: {
    isLoading: false,
    isSuccess: false,
    isModalLoading: false,
    customer: {
      id: '',
      title: '',
      firstName: '',
      lastName: '',
      dob: '',
      residentialStatus: '',
      bankDetails: [],
      emailAddress: '',
      mobileNumber: '',
      addresses: {
        current: {
          postCode: '',
          townOrCity: '',
          county: '',
          street: '',
          flatNameOrNumber: '',
          houseOrBuildingName: '',
          houseNumber: '',
          timeAtAddress: '',
        },
        previous: null,
      },
      application: {
        id: '',
        agreementNumber: '',
        applicationCompletedDate: null,
        assignedTo: null,
        hire: {
          deliveryAddress: '',
          deliveryDate: '',
          deliveryTime: '',
          totalAmount: null,
          monthlyRepayment: null,
          term: null,
          furniturePack: '',
          furnitureStyle: '',
          firstPaymentDueDate: null,
          monthlyPaymentDueDay: null,
          hireApprovalDate: null,
          hirePaybackCompletionDate: null,
        },

        firstPaymentDueDate: null,
        hirePaidDate: null,
        hirePaidBackDate: null,
        mandateId: '',
        monthlyRepayment: 0,
        term: 0,
        totalAmount: 0,
        financialStatus: {
          activeAccount: false,
          income: 0,
          rent: null,
          additionalIncome: 0,
          bills: 0,
          bnpl: 0,
          debt: 0,
          billsReason: null,
          travel: 0,
          travelMethod: null,
          food: 0,
          foodReason: null,
          clothing: 0,
          takeaways: 0,
          cashOut: 0,
          transfersOut: 0,
          gaming: 0,
          paydayLoans: null,
          expectIncomeToChange: null,
          expectIncomeToChangeReason: null,
          expectedIncome: null,
          otherIncome: 0,
          additionalIncomeIsIncome: false,
          cashOutIsBudget: false,
          transfersOutIsBudget: false,
          totalIncome: null,
          totalExpenditures: null,
        },
        consents: {
          termsAndConditions: false,
          termsAndConditionsTimestamp: null,
          credit: false,
          creditTimestamp: null,
          openBanking: false,
          openBankingTimestamp: null,
          directDebit: false,
          directDebitTimestamp: null,
          sanctions: null,
          sanctionsTimestamp: null,
          isAgreementSigned: false,
          agreementSignedTimestamp: null,
        },
        status: {
          currentStageId: null,
          applicationPhase: '',
          applicationStatus: '',
          rejectionReason: '',
        },
      },
      reports: {
        credit: null,
        sanctions: null,
        affordability: null,
      },
    },
    events: [],
    reports: {
      credit: null,
      sanctions: null,
      affordability: null,
    },
    error: null,
  } as any,
  reducers: {
    setStatus: (state, action) => {

      debugger
      state.customer = {
        ...state.customer,
        application: {
          ...state.customer.application,
          status: {
            ...state.customer.application.status,
            applicationStatus: action.payload.applicationStatus,
            rejectionReason: action.payload.rejectionReason,
            applicationPhase: action.payload.applicationPhase,
          },
        },
      };
    },
    setSuccess: (state, action) => {
      state.isSuccess = action.payload;
    },
    setSanctions: (state, action) => {
      state.reports.sanctions = action.payload;
    },
    setCredit: (state, action) => {
      state.reports.credit = action.payload;
    },
    setAffordability: (state, action) => {
      state.reports.affordability = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCustomerById.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getCustomerById.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.customer = payload.customer;
      state.reports.affordability = payload.reports.affordability;
      state.reports.sanctions = payload.reports.sanctions;
      state.reports.credit = payload.reports.credit;
    });
    builder.addCase(getCustomerById.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateProfile.pending, (state) => {
      state.isModalLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(updateProfile.fulfilled, (state, { payload }: any) => {
      state.isModalLoading = false;
      state.isSuccess = true;
      state.customer = {
        ...state.customer,
        ...payload,
      };
    });
    builder.addCase(updateProfile.rejected, (state) => {
      state.isModalLoading = false;
    });
    builder.addCase(updateHire.pending, (state) => {
      state.isModalLoading = true;
      state.isSuccess = false;
    });
    builder.addCase(updateHire.fulfilled, (state, { payload }: any) => {
      state.isModalLoading = false;
      state.isSuccess = true;

      state.customer = {
        ...state.customer,
        application: {
          ...state.customer.application,
          ...payload,
        },
      };
    });
    builder.addCase(updateHire.rejected, (state) => {
      state.isModalLoading = false;
    });
  },
});
export const getCustomerById = createAsyncThunk(
  'customers/getById',
  async (id: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await getCustomerByIdAPI(id);
      return response.data;
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
      return rejectWithValue(err.response.data);
    }
  }
);

export const updateProfile = createAsyncThunk(
  'customers/updateProfile',
  async (data: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const promises = [];

      const id = (getState() as RootState).customers.customer.id;
      const prepareAddressChanges = (addressChanges: any) => {
        ['current', 'previous'].forEach((type) => {
          if (addressChanges[type]) {
            addressChanges[type] = {
              ...addressChanges[type],
              postCode: addressChanges[type].postcode,
            };
            delete addressChanges[type].postcode;
          }
        });
        return addressChanges;
      };

      if (data?.addressChanges && Object.keys(data?.addressChanges).length) {
        promises.push(updateAddressAPI(id, prepareAddressChanges(data.addressChanges)));
      }

      if (data?.bankDetailsChanges && Object.keys(data?.bankDetailsChanges).length) {
        promises.push(updateBankDetailsAPI(id, data.bankDetailsChanges));
      }
      if (data.profileChanges && Object.keys(data?.profileChanges).length) {
        const profileChanges = { ...data.profileChanges };
        if (profileChanges.dob) {
          profileChanges.dob = moment(profileChanges.dob, 'DD/MM/YYYY').format('YYYY-MM-DD');
        }
        promises.push(updateProfileAPI(id, data.profileChanges));
      }

      const res = await Promise.all(promises);

      dispatch(
        showToast({
          message: 'Customer details updated',
          severity: 'success',
        })
      );

      return res.reduce((acc, response) => ({ ...acc, ...response.data }), {});
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const updateHire = createAsyncThunk(
  'customers/updateHire',
  async (data: any, { dispatch, getState, rejectWithValue }) => {
    try {
      const id = (getState() as RootState).customers.customer.id;

      const payload = data;

      if (payload) {
        payload.firstPaymentDueDate = moment(data.firstPaymentDueDate, 'DD/MM/YYYY').format(
          'YYYY-MM-DD'
        );
      }

      const response = await updateHireAPI(id, data);
      dispatch(
        showToast({
          message: 'Customer payment dates updated',
          severity: 'success',
        })
      );

      return response.data;
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const { setSuccess, setStatus, setCredit, setSanctions, setAffordability } =
  customersSlice.actions;

export default customersSlice.reducer;
