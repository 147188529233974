import React from 'react'
import CardContainer from '../../CardContainer/CardContainer'
import editIcon from '../../../assets/edit.svg'

interface IAddress {
    address1: string
    address2?: string
    address3?: string
    townOrCity: string
    postCode: string
}

interface IBankDetails {
    sortCode: string
    accountNumber: string
    bankName: string
}

function addDashes(sortCode: string | null) {
    return (
        sortCode &&
        sortCode.slice(0, 2) +
            '-' +
            (sortCode + '').slice(2, 4) +
            '-' +
            (sortCode + '').slice(4)
    )
}

function addSpaces(mobileNumber: string) {
    return (
        mobileNumber.slice(0, 5) +
        ' ' +
        mobileNumber.slice(5, 8) +
        ' ' +
        mobileNumber.slice(8)
    )
}

interface IProps {
    mobileNumber: string
    emailAddress: string
    addresses: IAddress[] | any
    bankDetails: IBankDetails[] | any
    onClick: () => void
    handleEmailClick: () => void
    handleSMSClick: () => void
    className: string
}

const DetailsInfoCard = ({
    onClick,
    handleSMSClick,
    handleEmailClick,
    mobileNumber,
    bankDetails,
    emailAddress,
    addresses,
    className,
}: IProps) => {

    return (
        <CardContainer
            title={'Details'}
            comment={'Application data'}
            className={`card ${className}`}
        >
            <button onClick={handleSMSClick} className="card__link">
                {addSpaces(mobileNumber)}
            </button>
            <button onClick={handleEmailClick} className="card__link">
                {emailAddress}
            </button>
            <p className="card__text">{addresses?.current?.addressFull}</p>
            {addresses?.previous?.addressFull && <p className="card__text">{addresses?.previous?.addressFull}</p>}
            <p className="card__text">
                {bankDetails.length ? addDashes(bankDetails[0].sortCode) : ''},{' '}
                {bankDetails.length ? bankDetails[0].accountNumber : ''}
            </p>
            <p className="card__text">
                {bankDetails.length ? bankDetails[0].bankName : ''}
            </p>
            <button className="card__edit-btn" onClick={onClick}>
                <img src={editIcon} alt="" />
            </button>
        </CardContainer>
    )
}

export default DetailsInfoCard
