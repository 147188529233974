import React, { useEffect } from 'react';
import { getUsers, resetPassword, addUser } from '../../app/features/users/usersSlice';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import Input from '../../components/input/Input';
import Title from '../../components/Title/Title';
import { FormikProvider, useFormik } from 'formik';
import * as Yup from 'yup';
import loader from '../../assets/loader.gif';
import './Users.scss';
import WarningAccountModal from '../../components/DialogModals/WarningAccountModal/WarningTemplateModal';

const Users = React.memo(() => {
  const dispatch = useAppDispatch();
  const { isLoading, isSuccess, users, isResetSuccess, isDeleteFinished } = useAppSelector(
    (state) => state.users
  );

  const [isNew, setIsNew] = React.useState<boolean>(false);
  const [passwordResetId, setPasswordResetId] = React.useState<string>('');
  const [deleteUserId, setDeleteUserId] = React.useState<string>('');
  const [openModal, setOpenModal] = React.useState<boolean>(false);

  useEffect(() => {
    dispatch(getUsers());
  }, []);

  const handleReset = (id: string) => {
    setPasswordResetId(id);
    dispatch(resetPassword(id));
  };
  const handleDelete = (id: string) => {
    setDeleteUserId(id);
    setOpenModal(true);
  };

  const schema = Yup.object().shape({
    fullName: Yup.string().min(2, 'Too short!').required(),
    emailAddress: Yup.string().email('Invalid email').required(),
  });

  const formik = useFormik({
    initialValues: {
      fullName: '',
      emailAddress: '',
    },
    validationSchema: schema,
    onSubmit: () => {},
  });

  useEffect(() => {
    setIsNew(false);
    formik.resetForm();
  }, [isSuccess]);

  useEffect(() => {
    setPasswordResetId('');
  }, [isResetSuccess]);

  useEffect(() => {
    setDeleteUserId('');
  }, [isDeleteFinished]);

  return (
    <div className="users">
      <Title text={'Users.'} />
      <div className="users-table">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Username</th>
              <th>Password</th>
              <th>Account</th>
            </tr>
          </thead>
          <tbody>
            {users.length > 0 &&
              users.map((elem: any) => {
                return (
                  <tr key={elem.id}>
                    <td>{elem.fullName}</td>
                    <td>{elem.emailAddress}</td>
                    <td>
                      {elem.password ? (
                        elem.password
                      ) : isLoading && passwordResetId === elem.id ? (
                        <div className="users__loader">
                          <img src={loader} alt="loader" />
                        </div>
                      ) : (
                        <button onClick={() => handleReset(elem.id)} className={'btn'}>
                          Reset
                        </button>
                      )}
                    </td>
                    <td>
                      <button onClick={() => handleDelete(elem.id)} className={'btn'}>
                        Delete
                      </button>
                    </td>
                  </tr>
                );
              })}
            <tr>
              <FormikProvider value={formik}>
                <td className={'input'} onClick={() => setIsNew(true)}>
                  {isNew ? (
                    <Input
                      name="fullName"
                      value={formik.values.fullName}
                      error={formik.touched.fullName && formik.errors.fullName}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        formik.handleChange(e);
                      }}
                      // onBlur={formik.handleBlur}
                    />
                  ) : (
                    <button className={'btn'}>New...</button>
                  )}
                </td>
                <td className={'input'}>
                  {isNew && (
                    <Input
                      name="emailAddress"
                      value={formik.values.emailAddress}
                      error={formik.touched.emailAddress && formik.errors.emailAddress}
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        formik.handleChange(e);
                      }}
                      // onBlur={formik.handleBlur}
                    />
                  )}
                </td>
                <td></td>
                <td>
                  {isNew &&
                    (isLoading ? (
                      <div className="users__loader">
                        <img src={loader} alt="loader" />
                      </div>
                    ) : (
                      <button
                        className={'btn'}
                        disabled={!(formik.isValid && formik.dirty)}
                        onClick={() => dispatch(addUser(formik.values))}
                      >
                        Create
                      </button>
                    ))}
                </td>
              </FormikProvider>
            </tr>
          </tbody>
        </table>
      </div>
      <WarningAccountModal
        visible={openModal}
        id={deleteUserId}
        onClose={() => setOpenModal(false)}
      />
    </div>
  );
});

export default Users;
