import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { showToast } from '../ui/uiSlice';
import { getLatestCustomersAPI, getStatisticsAPI } from './currentStatsAPI';

export interface IDashboardState {
  latestCustomers: {
    total: CustomerStatsType[];
    completed: CustomerStatsType[];
    successes: CustomerStatsType[];
    approved: CustomerStatsType[];
  };
  statistics: StatsType;
  isLoading: boolean;
}

export type CustomerStatsType = {
  uuid: string;
  title: string;
  firstName: string;
  lastName: string;
  applicationStatus: string;
  applicationPhase: string;
  totalAmount: number;
};

export type StatsType = {
  credit: number;
  affordability: number;
  sanctions: number;
  total: number;
  completed: number;
  successes: number;
  approved: number;
  introduced: number;
};

const initialState: IDashboardState = {
  latestCustomers: {
    total: [],
    completed: [],
    successes: [],
    approved: [],
  },
  statistics: {
    credit: 0,
    affordability: 0,
    sanctions: 0,
    completed: 0,
    successes: 0,
    total: 0,
    approved: 0,
    introduced: 0,
  },
  isLoading: false,
};

const slice = createSlice({
  name: 'dashboard',
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getLatestStats.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLatestStats.fulfilled, (state, { payload }) => {
      state.statistics = payload;
      state.isLoading = false;
    });
    builder.addCase(getLatestStats.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getLatestCustomers.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLatestCustomers.fulfilled, (state, { payload }) => {
      state.latestCustomers = payload;
      state.isLoading = false;
    });
    builder.addCase(getLatestCustomers.rejected, (state) => {
      state.isLoading = false;
    });
  },
});
export const getLatestStats = createAsyncThunk(
  'dashboard/getLatestStatistics',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await getStatisticsAPI();
      return response.data;
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const getLatestCustomers = createAsyncThunk(
  'dashboard/getLatestCustomers',
  async (data, { dispatch, rejectWithValue }) => {
    try {
      const response = await getLatestCustomersAPI();
      return response.data;
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const latestStatsReducer = slice.reducer;
