import React from 'react';
import CardContainer from '../../CardContainer/CardContainer';
import cx from 'classnames';
import { formatMoney } from '../../../utils/formatMoney';
import './AccountScoreCard.scss';
import { IndicatorStatus } from '../../Indicator/Indicator';

interface IProps {
  onClick?: () => void;
  className: string;
  report: any;
  display?: 'full' | 'combined' | 'limited';
}

const AccountScoreCard = ({ onClick, report, className, display = 'combined' }: IProps) => {
  const displayCriteriaNumber = ['Transaction volume'];
  const displayCriteriaBoolean = ['Account holder', 'Overall affordability'];
  const displayCriteriaCurrency = ['Final income vs. expenditure', 'Repayments on existing debts'];

  const isDataExist = !!report;

  const { criteria, questions } = report?.data || {};

  const isGreen = criteria?.every((elem: any) => elem.status === true);
  const status = isGreen ? IndicatorStatus.SUCCESS : IndicatorStatus.FAIL;

  if (display === 'full') {
    return (
      <CardContainer
        onClick={onClick}
        size={'full'}
        title={'Affordability report'}
        comment={'AccountScore'}
        {...(isDataExist && { status })}
        className={cx(className, 'accountscore', {})}
      >
        <div className="accountscore_block">
          <div className="accountscore__col">
            <div className="accountscore__item">
              <p className="accountscore__text">Criteria</p>
            </div>
            {criteria.map((elem: any, idx: number) => {
              return (
                <div key={idx} className="accountscore__item">
                  <p className="accountscore__text">{elem.name}</p>
                  <p className={`accountscore__text ${elem.status ? 'green' : 'red'}`}>
                    {displayCriteriaNumber.includes(elem.name)
                      ? elem.value
                      : displayCriteriaCurrency.includes(elem.name)
                        ? `${formatMoney(elem.value)}`
                        : displayCriteriaBoolean.includes(elem.name) && elem.status === true
                          ? 'Yes'
                          : displayCriteriaBoolean.includes(elem.name) && elem.status === false
                            ? 'No'
                            : typeof elem.percent === 'string'
                              ? elem.percent
                              : `${elem.percent}%`}
                  </p>
                </div>
              );
            })}
            <div className="accountscore__link">
              <a
                target="_blank"
                href={`https://dashboard.accountscore.net/client/familio/Dashboard?customerReference=${report.id}`}
              >
                See this applicant on AccountScore
              </a>
            </div>
          </div>
          <div className="accountscore__col">
            <div className="accountscore__item">
              <p className="accountscore__text">Questions</p>
            </div>
            {questions.map((elem: any, idx: number) => {
              return (
                <div key={idx} className={`accountscore__item ${!elem.asked ? 'disabled' : ''}`}>
                  <p className="accountscore__text">{elem.name}</p>
                  <p className={'accountscore__text'}>
                    {typeof elem.value === 'boolean'
                      ? elem.value && elem.asked
                        ? 'Yes'
                        : !elem.value && !elem.asked
                          ? ''
                          : 'No'
                      : formatMoney(elem.value)}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </CardContainer>
    );
  }

  return (
    <CardContainer
      {...(isDataExist && { status })}
      {...(isDataExist && { onClick: onClick })}
      title={'Affordability report'}
      comment={'ACCOUNTSCORE'}
      className={cx(className, {
        limited: display === 'limited',
      })}
    >
      {isDataExist && display !== 'limited' && (
        <>
          {criteria.map((elem: any, idx: number) => {
            return (
              idx < 6 && (
                <div key={idx}>
                  <div className="card__flex">
                    <p className="card__text">{elem.name}</p>
                    <p className={`accountscore__text ${elem.status ? 'green' : 'red'}`}>
                      {displayCriteriaNumber.includes(elem.name)
                        ? elem.value
                        : displayCriteriaCurrency.includes(elem.name)
                          ? `${formatMoney(elem.value)}`
                          : displayCriteriaBoolean.includes(elem.name) && elem.status === true
                            ? 'Yes'
                            : displayCriteriaBoolean.includes(elem.name) && elem.status === false
                              ? 'No'
                              : typeof elem.percent === 'string'
                                ? elem.percent
                                : `${elem.percent}%`}
                    </p>
                  </div>
                </div>
              )
            );
          })}
        </>
      )}
    </CardContainer>
  );
};

export default AccountScoreCard;
