import CustomAxios from '../../customAxios/CustomAxios';

export type PayloadSendEmailType = {
  content: Object;
  subject: string;
};

export type PayloadSendSmsType = {
  message: string;
};

export const sendEmailAPI = (data: any) => {
  return CustomAxios.post(
    `${process.env.REACT_APP_COMMUNICATIONS_URL}/communications/email`,
    data
  );
};
export const sendSmsAPI = (data: any) => {
  return CustomAxios.post(
    `${process.env.REACT_APP_COMMUNICATIONS_URL}/communications/sms`,
    data
  );
};

export const getEmailApi = (customerId: string, id: number) => {
  return CustomAxios.get(
    `${process.env.REACT_APP_COMMUNICATIONS_URL}/communications/${customerId}/email/${id}`
  );
};
export const getSMSApi = (customerId: string, id: number) => {
  return CustomAxios.get(
    `${process.env.REACT_APP_COMMUNICATIONS_URL}/communications/${customerId}/sms/${id}`
  );
};