import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getAllEvents } from './eventsAPI';

type SliceState = {
  isLoading: boolean;
  error: null | string;
  events: [];
};
export const eventsSlice = createSlice({
  name: 'events',
  initialState: {
    isLoading: false,
    error: null,
    events: [],
  } as SliceState,
  reducers: {},
  extraReducers: (b) => {
    b.addCase(getEvents.pending, (state) => {
      state.isLoading = true;
    });
    b.addCase(getEvents.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      state.events = payload;
    });
    b.addCase(getEvents.rejected, (state) => {
      state.isLoading = false;
      state.error = 'ERROR';
    });
  },
});
export const getEvents = createAsyncThunk(
  'events/get',
  async (id: any, { rejectWithValue, dispatch }) => {
    try {
      const response: any = await getAllEvents(id);
      return response.data;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data?.message || 'Internal server error');
    }
  }
);
export default eventsSlice.reducer;
