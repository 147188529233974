import React from 'react';
import Card from '../../Card/Card';
import pdf from '../../../assets/pdf.svg';
import Modal from '../../Modal/Modal';
import { format, parseISO } from 'date-fns';

const CommunicationModal = ({ show, onClose, modalType, data }: any) => {
  let emailHeader: Record<string, string | number> = {};
  let SMSHeader: Record<string, string | number> = {};

  emailHeader = {
    From: data?.senderEmail || 'undefined',
    Date: data?.sentAt && format(parseISO(data?.sentAt), 'HH:mm dd/MM/y'),
    Subject: data?.subject,
    To: data?.recipientEmail,
  };

  SMSHeader = {
    Date: data?.sentAt && format(parseISO(data?.sentAt), 'HH:mm dd/MM/y'),
    To: data?.recipientNumber,
  };

  return (
    <Modal show={show} onClose={() => onClose(false)}>
      {modalType === 'sms' ? (
        <Card className={`events__modal_sms`} color={'light'} size={'full'}>
          <div className="modal__top">
            {Object.entries(SMSHeader).map(([key, value], idx) => {
              return (
                <p key={idx} className="modal__item">
                  <span className="modal__name">{key}:</span>
                  <span className="modal__info">{value || 'N/A'}</span>
                </p>
              );
            })}
          </div>
          <div className="modal__body">{data.body}</div>
        </Card>
      ) : (
        <Card className={`events__modal_email`} color={'light'} size={'full'}>
          <div className="modal__top">
            {Object.entries(emailHeader).map(([key, value], idx) => {
              return (
                <p key={idx} className="modal__item">
                  <span className="modal__name">{key}:</span>
                  <span className="modal__info">{value || 'N/A'}</span>
                </p>
              );
            })}
          </div>
          <div className="modal__body">
            {data && (
              <div
                className="modal__text"
                dangerouslySetInnerHTML={{
                  __html: data.htmlContent,
                }}
              />
            )}
            {data && data.attachments && (
              <div className="modal__attachments">
                {data.attachments.map((elem: any, idx: number) => {
                  return (
                    <a
                      href={elem.fileUrl}
                      target={'_blank'}
                      key={idx}
                      className="modal__attachment"
                    >
                      <img src={pdf} alt="pdf" />
                      <p className="modal__filename">{elem.fileName}</p>
                    </a>
                  );
                })}
              </div>
            )}
          </div>
        </Card>
      )}
    </Modal>
  );
};

export default CommunicationModal;
