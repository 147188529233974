import React, { useEffect, useState } from 'react';
import './Navbar.scss';
import settingsIcon from '../../assets/settings.svg';
import searchIcon from '../../assets/search.svg';
import { Fade, Slide } from 'react-awesome-reveal';
import notificationsIcon from '../../assets/notifications.svg';
import logo from '../../assets/logo.svg';
import beep from '../../assets/notification.mp3';
import { Link, NavLink, useLocation } from 'react-router-dom';
import Searchbar from '../Searchbar/Searchbar';
import { searchCustomersAPI } from '../../app/features/customers/customersAPI';
import { useAppSelector } from '../../app/hooks';
import ReactPlayer from 'react-player';
import { changeFavIcon } from '../../helpers/favIconNotify';
import { useAppDispatch } from '../../app/store';
import { singOut } from '../../app/features/auth/authSlice';

const { useDebounce } = require('use-lodash-debounce');

type SubmenuType = {
  name: string;
  link?: string;
  visible: boolean;
  action?: () => void;
};

type NavbarType = {
  setTheme: (theme: 'light' | 'dark' | 'overlay') => void;
};

export type searchResultType = {
  currentAddress: Object;
  agreementNumber: string;
  createdAt: string;
  dob: string;
  firstName: string;
  lastName: string;
  totalAmount: number;
  status: string;
  customerId: string;
};

const Navbar = ({ setTheme }: NavbarType) => {
  const dispatch = useAppDispatch();

  const handleLogout = () => {
    dispatch(singOut());
  };

  const { customer } = useAppSelector((state) => state.customers);

  const { isNew } = useAppSelector((state) => state.notificationsReducer);

  changeFavIcon(isNew);

  const id = customer.id;

  const [open, setOpen] = React.useState<boolean>(false);
  const [searchValue, setSearchValue] = React.useState<string>('');
  const [showMenu, setShowMenu] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [showResults, setShowResults] = React.useState<boolean>(false);
  const [inProgress, setInProgress] = React.useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(isNew);

  const [data, setData] = React.useState<searchResultType[]>([]);

  const debouncedValue = useDebounce(searchValue, 300);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      setInProgress(true);
      const { data } = await searchCustomersAPI(searchValue);
      setData(data);
      setIsLoading(false);
      setInProgress(false);
    };
    if (searchValue.length >= 3) {
      fetchData();
    }
  }, [debouncedValue]);

  useEffect(() => {
    if (!isLoading && !inProgress) {
      setShowResults(true);
    }
  }, [isLoading, inProgress]);

  if (!searchValue && showResults) {
    setShowResults(false);
  }

  const handleShow = () => {
    if (data.length && searchValue) {
      setShowResults(true);
    } else {
      setShowMenu(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
    setSearchValue('');
    setShowMenu(false);
    setTheme('light');
  };

  let location = useLocation();

  const path = location.pathname.split('/')[1];
  const end = location.pathname.split('/').reverse()[0];

  const submenu: { [key: string]: SubmenuType[] } = {
    settings: [
      { name: 'Profile', link: '/settings', visible: true },
      { name: 'Templates', link: '/settings/templates', visible: true },
      { name: 'Users', link: '/settings/users', visible: true },
      { name: 'Logout', action: handleLogout, visible: true },
    ],
    reports: [
      { name: 'Funded', link: '/reports', visible: true },
      {
        name: 'MI',
        link: '/reports/management-information',
        visible: true,
      },
      { name: 'Journey', link: '/reports/journey', visible: true },
    ],
    dashboards: [
      { name: 'Live', link: '/dashboards', visible: true },
      {
        name: 'Introduced',
        link: '/dashboards/introduced',
        visible: true,
      },
      { name: 'Leads', link: '/dashboards/leads', visible: true },
      { name: 'Apps', link: '/dashboards/applications', visible: true },
      { name: 'Success', link: '/dashboards/successes', visible: true },
      { name: 'Paid out', link: '/dashboards/paid-out', visible: true },
    ],
    customers: [
      { name: 'Search', link: '/customers', visible: true },
      {
        name: 'Profile',
        link: `/customers/${id}`,
        visible: !!id,
      },
      {
        name: 'Events',
        link: `/customers/${id}/events`,
        visible: !!id,
      },
      {
        name: 'Journey',
        link: `/customers/${id}/journey`,
        visible: !!id,
      },
    ],
  };

  const handleSearch = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setSearchValue(e.currentTarget.value);
    setShowResults(false);
    data.length && setData([]);
  };

  const handleInputLeave = () => {
    setShowMenu(false);
    setShowResults(false);
    setShowMenu(false);
  };

  const openSearchBar = () => {
    setTheme('overlay');
    setShowMenu(true);
    setOpen(true);
  };

  return (
    <div className="navbar">
      <ReactPlayer
        url={beep}
        playing={isPlaying}
        height={0}
        width={0}
        onEnded={() => {
          setIsPlaying(false);
        }}
      />
      <div className="navbar__wrapper">
        <div className="navbar__top">
          {!open ? (
            <>
              <div className="navbar__logo">
                <NavLink to={'/'}>
                  <img src={logo} alt="" />
                </NavLink>
              </div>
              <ul className="navbar__main-menu menu">
                <li className="menu__item">
                  <NavLink to={'/customers'} className={'customers'}>
                    Customers
                  </NavLink>
                </li>
                <li className="menu__item">
                  <NavLink to={'/dashboards'} className={'dashboards'}>
                    Dashboards
                  </NavLink>
                </li>
                <li className="menu__item">
                  <NavLink to={'/reports'} className={'reports'}>
                    Reports
                  </NavLink>
                </li>
                <li className="menu__item">
                  <NavLink to={'/settings'} className={'settings'}>
                    <img src={settingsIcon} alt="" />
                  </NavLink>
                </li>
                <li className={`menu__item ${isNew && 'new'}`}>
                  <NavLink to={'/notifications'}>
                    <img src={notificationsIcon} alt="" />
                  </NavLink>
                </li>
                <li className="menu__item">
                  <button onClick={openSearchBar}>
                    <img src={searchIcon} alt="" />
                  </button>
                </li>
              </ul>
            </>
          ) : (
            <Searchbar
              // onBlur={handleInputLeave}
              onClick={handleShow}
              onChange={handleSearch}
              onClose={handleClose}
              showMenu={showMenu}
              showResults={showResults}
              value={searchValue}
              // isLoading={isLoading}
              data={data}
            ></Searchbar>
          )}
        </div>
        {!searchValue && showMenu && (
          <ul className="search__results">
            <span>Quick links</span>
            <Link onClick={handleClose} to={'customers'}>
              Customers
            </Link>
            <Link onClick={handleClose} to={'dashboards'}>
              Dashboard
            </Link>
            <Link onClick={handleClose} to={'reports'}>
              Reports
            </Link>
            <Link onClick={handleClose} to={'settings'}>
              Settings
            </Link>
          </ul>
        )}
        {searchValue && showResults && (
          <ul className="search__results">
            <>
              <span>Results</span>
              {data.length === 0 && <div>no results</div>}
              {!!data.length &&
                data.map((elem: searchResultType, i: number) => {
                  if (i < 3) {
                    return (
                      <Link
                        key={elem.customerId}
                        onClick={handleClose}
                        to={`/customers/${elem.customerId}`}
                      >
                        {elem.firstName} {elem.lastName}
                      </Link>
                    );
                  }
                })}
              {!!data.length && data.length > 3 && (
                <Link to={`/customers/search?q=${searchValue}`} onClick={handleClose}>
                  See more...
                </Link>
              )}
            </>
          </ul>
        )}
        {Object.keys(submenu).includes(path) && (
          <Slide direction={'down'}>
            <div className="navbar__bottom">
              <ul className="navbar__sub-menu menu">
                {submenu[path].map((elem: SubmenuType, i: number) => {
                  if (elem.visible) {
                    return (
                      <li key={i} className="menu__item">
                        {elem.link ? (
                          <Link
                            to={`${elem.link}`}
                            className={end === elem.link.split('/').reverse()[0] ? 'active' : ''}
                          >
                            {elem.name}
                          </Link>
                        ) : (
                          <button onClick={elem.action}>{elem.name}</button>
                        )}
                      </li>
                    );
                  }
                })}
              </ul>
            </div>
          </Slide>
        )}

        {isNew && (
          <Fade direction={'down'}>
            <div className="navbar__notification">
              You have notifications.
              <Link to={'/notifications'}>Click here now {'>'}</Link>
            </div>
          </Fade>
        )}
      </div>
    </div>
  );
};

export default React.memo(Navbar);
