import React, { useState } from 'react';
import './CustomersTableContainer.scss';
import CustomersTable from '../CustomersTable/CustomersTable';
import FakeDropdown from '../FakeDropdown/FakeDropdown';
import Modal from '../Modal/Modal';
import DatePickerInput from '../DatePickerInput/DatePickerInput';
import Title from '../Title/Title';
import { ReportType } from '../../app/features/reports/reportsSlice';
import CsvDownload from 'react-json-to-csv';
import { format } from 'date-fns';

export type DateType = {
  rangeName: string;
  from: Date;
  to: Date;
};

interface ICustomersTableContainer {
  data: ReportType[] | [];
  title: string;
  date: DateType;
  selectedDate: (date: DateType) => void;
  dropdownName: string;
  isLoading: boolean;
}

const CustomersTableContainer = ({
  isLoading,
  data,
  title,
  date,
  selectedDate,
  dropdownName,
}: ICustomersTableContainer) => {
  const [isOpen, setIsOpen] = useState(false);

  const formatDataCsv = data.map((elem: any) => {
    return {
      id: elem.uuid,
      fullName: `${elem.firstName} ${elem.lastName}`,
      totalAmount: elem.totalAmount,
      status: elem.status,
      agreementNumber: elem.agreementNumber,
      date: format(new Date(elem.createdAt), 'dd/MM/yyyy'),
      time: format(new Date(elem.createdAt), 'HH:mm'),
      url: 'https://fuse.familio.co.uk/customers/' + elem.uuid,
    };
  });

  return (
    <div className="customers-table">
      <div className="customers-table__header">
        <Title text={title} />
        <FakeDropdown label={'Date'} onClick={() => setIsOpen(!isOpen)}>
          {dropdownName}
        </FakeDropdown>
      </div>
      <div className="customers-table__table table">
        <CustomersTable isLoading={isLoading} customers={data} />
      </div>
      <div className="customers-table__btn-block">
        <CsvDownload className="customers-table__btn" filename={title + 'csv'} data={formatDataCsv}>
          Download csv
        </CsvDownload>
      </div>
      <Modal show={isOpen} onClose={() => setIsOpen(false)}>
        <DatePickerInput
          onClose={() => setIsOpen(false)}
          date={date}
          handleSelectedDates={selectedDate}
        />
      </Modal>
    </div>
  );
};

export default CustomersTableContainer;
