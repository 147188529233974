import React from 'react';
import CardContainer from '../../CardContainer/CardContainer';

interface IProps {
  monthlyRepayment: number | null;
  term: number | null;
  totalAmount: number | null;
  onClick: () => void;
  className: string;
}

const LoanInfoCard = ({
  onClick,
  totalAmount,
  term,
  monthlyRepayment,
  furnitureStyle,
  furniturePack,
  className,
}: any) => {
  return (
    <CardContainer
      title={'Hire'}
      comment={'INFORMATION ABOUT THE HIRE'}
      className={`card ${className}`}
      onClick={onClick}
    >
      <h1 className="card__info">£{totalAmount}</h1>
      <p className="card__text">{term} months</p>
      <p className="card__text">£{monthlyRepayment} per month</p>
      <p className="card__text">
        {furniturePack} {furnitureStyle}
      </p>
    </CardContainer>
  );
};

export default LoanInfoCard;
