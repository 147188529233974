import React, { useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
import './App.scss';
import Navbar from './components/Navbar/Navbar';
import LoginPage from './pages/LoginPage/LoginPage';
import { useAppSelector } from './app/hooks';
import { RootState, useAppDispatch } from './app/store';
import Toast from './components/ErrorToast/ErrorToast';
import { getMe, logOut } from './app/features/auth/authSlice';
import { startConnecting } from './app/features/notifications/notificationSlice';
import authRequiredRoutes from './routes/authRequiredroutes';
import Homepage from './pages/Homepage/Homepage';

const App = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { isAuth } = useAppSelector((state: RootState) => state.auth);
  const [theme, setTheme] = useState('light');
  const handleSetTheme = (theme: 'light' | 'dark' | 'overlay') => setTheme(theme);


  useEffect(() => {
    // @ts-ignore
    if (theme === 'dark') {
      document.body.classList.add('dark');
    } else {
      document.body.classList.remove('dark');
    }
  }, [theme]);

  useEffect(() => {
    if (isAuth) dispatch(startConnecting());
  }, [isAuth]);

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      dispatch(logOut());
      navigate('/login');
    } else {
      dispatch(getMe());
    }
  }, []);

  const renderRoutes = () => {
    if (!isAuth) {
      return <Route key="login" path="/login" element={<LoginPage />} />;
    }
    return authRequiredRoutes(theme, setTheme);
  };

  return (
    <div className="App">
      {isAuth && <Navbar setTheme={handleSetTheme} />}
      <div className="container">
        <div className="content">
          <div className="app-overlay" />
          <Toast />
          <Routes>
            <Route path="/" element={isAuth ? <Homepage /> : <LoginPage />} />
            {renderRoutes()}
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default App;
