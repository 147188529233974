import CustomAxios from "../../customAxios/CustomAxios";

export const refreshAffordabilityAPI = (id: string) => {
    return CustomAxios.get(`${process.env.REACT_APP_SEARCHES_URL}/searches/${id}/refresh-affordability`);
};
export const getSanctionsAPI = (id: string) => {
    return CustomAxios.get(`${process.env.REACT_APP_SEARCHES_URL}/searches/${id}/sanctions-check`);
};
export const getCreditAPI = (id: string) => {
    return CustomAxios.get(`${process.env.REACT_APP_SEARCHES_URL}/searches/${id}/credit-check`);
};