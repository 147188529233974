import CustomAxios from '../../customAxios/CustomAxios';

export const changeStatusAPI = (id: string, data: any) => {
  return CustomAxios.post(`${process.env.REACT_APP_DECISIONS_URL}/decisions/${id}/manage`, data);
};
export const payoutCustomerAPI = (id: string, data: { password: string }) => {
  return CustomAxios.post(`${process.env.REACT_APP_DECISIONS_URL}/decisions/${id}/payout`, data);
};
export const assignCustomerAPI = (customerId: string, toAdminId: string) => {
  return CustomAxios.post(`${process.env.REACT_APP_DECISIONS_URL}/decisions/assign/${customerId}/to/${toAdminId}`, {});
};
export const shareCustomerAPI = (customerId: string, withAdminId: string) => {
  return CustomAxios.post(`${process.env.REACT_APP_DECISIONS_URL}/decisions/share/${customerId}/with/${withAdminId}`, {});
};