import React from 'react';
import editIcon from '../../../assets/edit.svg';
import CardContainer from '../../CardContainer/CardContainer';

interface IProps {
  fullName: string;
  dob: string;
  agreementNumber?: string;
  prefix: string;
  className?: string;
  onClick?: () => void;
}

const ApplicantInfoCard = ({
  onClick,
  prefix,
  fullName,
  dob,
  agreementNumber,
  className,
}: IProps) => {
  return (
    <CardContainer
      title={'Applicant'}
      comment={'Information about the applicant'}
      className={`card ${className}`}
    >
      <h1 className="card__info">
        {prefix} {fullName}
      </h1>
      <p className="card__text">{dob}</p>
      <p className="card__text">{agreementNumber || ''}</p>
      <button onClick={onClick} className="card__edit-btn">
        <img src={editIcon} alt="" />
      </button>
    </CardContainer>
  );
};

export default ApplicantInfoCard;
