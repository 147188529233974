import React, { useState } from 'react';
import { Form, FormikProvider, Field, useFormik } from 'formik';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import Input from '../../components/input/Input';
import Button from '../../components/Button/Button';
import Editor from '../../components/TextEditor/TextEditor';
import BtnGroup from '../../components/BtnGroup/BtnGroup';
import { formatToSelect } from '../../utils/formatToSelect';
import { RootState, useAppDispatch } from '../../app/store';
import { useAppSelector } from '../../app/hooks';
import * as Yup from 'yup';
import {
  createEmailTemplate,
  createSmsTemplate,
  EmailTemplateType,
  getAllEmails,
  getAllSms,
  SmsTemplateType,
  updateEmailTemplate,
  updateSmsTemplate,
} from '../../app/features/templates/templatesSlice';

interface Templates {
  email: EmailTemplateType[];
  sms: SmsTemplateType[];
}

type PropsType = {
  setShowModal: (value: boolean) => void;
  selectedMediaOption: 'sms' | 'email' | null;
  selectedOption: number | null;
  setSelectedMediaOption: (value: 'sms' | 'email' | null) => void;
  setSelectedOption: (value: number | null) => void;
};
const TemplatesForm = ({
  setShowModal,
  selectedMediaOption,
  selectedOption,
  setSelectedMediaOption,
  setSelectedOption,
}: PropsType) => {
  const dispatch = useAppDispatch();
  const { isLoading, isSuccess, isDeleteSuccess, emailList, smsList } = useAppSelector(
    (state: RootState) => state.templates
  );

  const [activeTemplates, setActiveTemplates] = useState<any[]>([]);
  const [content, setContent] = React.useState('');
  const [contentTouched, setContentTouched] = React.useState<boolean>(false);
  const [editView, setEditView] = React.useState<boolean>(true);
  const editorRef: React.MutableRefObject<any | null> = React.useRef(null);

  const mediaOptions = [
    { label: 'SMS', value: 'sms' },
    { label: 'Email', value: 'email' },
  ];

  const schema = Yup.object({
    name: Yup.string().required('required'),
  });

  const handleViewChange = () => {
    handleCancel();
    setEditView(!editView);
  };

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: schema,
    onSubmit: () => {},
  });
  const handleContentChanges = (content: string) => {
    setContentTouched(true);
    if (selectedMediaOption && selectedOption) {
      setContent(content);
      setContentTouched(true);
    }
  };

  React.useEffect(() => {
    setActiveTemplates(formatToSelect(selectedMediaOption === 'sms' ? smsList : emailList));
  }, [emailList, smsList]);

  const getContent = (id: number) => {
    const templates: Templates = {
      email: emailList,
      sms: smsList,
    };

    // @ts-ignore
    const selectedTemplateList = templates[selectedMediaOption];
    const selectedTemplate = selectedTemplateList && selectedTemplateList.find((elem: EmailTemplateType | SmsTemplateType) => elem.id === id);
    editorRef.current.clearContent();
    if (selectedMediaOption === 'email') {
      editorRef.current.insertContent(selectedTemplate.htmlContent);
    } else {
      editorRef.current.insertContent(selectedTemplate.content);
    }
  };
  const handleActiveTemplate = (template: 'sms' | 'email') => {
    setActiveTemplates([]);
    setSelectedMediaOption(template);
    if (template === 'email') dispatch(getAllEmails());
    if (template === 'sms') dispatch(getAllSms());
    editorRef.current.clearContent();
  };

  const renderMediaField = () => (
    <Field
      label={'Media'}
      optionsList={mediaOptions}
      onChange={(e: any) => handleActiveTemplate(e.value)}
      selectedOption={mediaOptions.filter((o: any) => o.value === selectedMediaOption)}
      className={`templates__field`}
      placeholder={'Email or SMS?'}
      as={CustomSelect}
    />
  );

  const handleSelectedTemplate = (id: number) => {
    setSelectedOption(id);
    getContent(id);
  };
  const renderTemplateField = () =>
    editView ? (
      <Field
        label={'Template'}
        optionsList={activeTemplates}
        onChange={(e: any) => handleSelectedTemplate(e.value)}
        selectedOption={activeTemplates.filter((o: any) => o.value === selectedOption)}
        className={`templates__field`}
        placeholder={'Pick a template to edit'}
        as={CustomSelect}
      />
    ) : (
      <Field
        id="name"
        name="name"
        placeholder={'Name your template'}
        onChange={formik.handleChange('name')}
        error={formik.touched.name && formik.errors.name}
        className={`templates__field`}
        label={'Template name'}
        as={Input}
      />
    );

  const handleCancel = () => {
    setSelectedOption(null);
    setSelectedMediaOption(null);
    setContent('');
    editorRef.current.clearContent();
  };

  const handleSend = () => {
    const content = selectedMediaOption === 'sms' ? editorRef.current.getText() : editorRef.current.getHTML();
    const updateAction = selectedMediaOption === 'email' ? updateEmailTemplate : updateSmsTemplate;
    dispatch(updateAction({id: selectedOption, content}));
  };

  const isInputDisabled = (contentTouched && selectedMediaOption === null) || selectedOption === null;
  const renderButtons = () => (
    <div className={`templates__btn-block`}>
      {editView ? (
        <>
          <Button
            disabled={!contentTouched && !selectedMediaOption}
            onClick={handleCancel}
            className={`templates__btn`}
          >
            Cancel
          </Button>
          <Button
            disabled={!selectedOption}
            onClick={() => setShowModal(true)}
            className={`templates__btn`}
          >
            Delete
          </Button>
          <Button disabled={isInputDisabled} className={`templates__btn`} onClick={handleSend}>
            Save
          </Button>
        </>
      ) : (
        <BtnGroup
          className={'applicant-data__btn-block'}
          isActive={formik.dirty || formik.isSubmitting}
          onCancel={() => formik.resetForm()}
          onClick={() => {
            const content = selectedMediaOption === 'sms' ? editorRef.current.getText() : editorRef.current.getHTML();
            if (selectedMediaOption === 'email') dispatch(createEmailTemplate({name: formik.values.name, content}));
            if (selectedMediaOption === 'sms') dispatch(createSmsTemplate({name: formik.values.name, content}));
          }}
          isLoading={isLoading}
          isSuccess={isSuccess}
        />
      )}
    </div>
  );

  return (
    <div className={`templates__body`}>
      <div className="templates__subtitle-block">
        <h2 className="templates__subtitle">{editView ? 'Edit template' : 'Create template'}</h2>
        <Button onClick={handleViewChange} className="templates__btn">
          {editView ? 'New' : 'Edit'}
        </Button>
      </div>
      <FormikProvider value={formik}>
        <Form className="applicant-data__form form" autoComplete="off">
          {renderMediaField()}
          {renderTemplateField()}
          <Editor
            content={content}
            className={'templates__editor'}
            label={'Content'}
            ref={editorRef}
            onChange={handleContentChanges}
          />
          {renderButtons()}
        </Form>
      </FormikProvider>
    </div>
  );
};

export default TemplatesForm;
