import React from 'react';
import CardContainer from '../../CardContainer/CardContainer';
import cx from 'classnames';
import { formatMoney } from '../../../utils/formatMoney';
import './CreditRiskCard.scss';
import { IndicatorStatus } from '../../Indicator/Indicator';

const TYPES = {
  NUMBER: 'number',
  BOOL: 'boolean',
  PERCENT: 'percent',
  CURRENCY: 'currency',
};
const numberOfPassedFields = (data: any[]) =>
  data.reduce((acc, elem) => (!elem.status ? acc + 1 : acc), 0);

interface IProps {
  onClick?: () => void;
  className: string;
  report: any;
  display?: 'full' | 'combined' | 'limited';
}

const setStatus = (result: boolean, match: boolean) => {
  if (!result) return match ? IndicatorStatus.FAIL : IndicatorStatus.NO_MATCH;
  return IndicatorStatus.SUCCESS;
};

const creditValuesDisplayTypes = [
  { display: TYPES.BOOL, name: 'Power of attourney' },
  { display: TYPES.BOOL, name: 'Deceased' },
  { display: TYPES.BOOL, name: 'Gone away' },
  { display: TYPES.NUMBER, name: 'Accounts in sector 2' },
  { display: TYPES.NUMBER, name: 'Accounts in default' },
  { display: TYPES.PERCENT, name: 'Defaults as % of balance' },
  { display: TYPES.NUMBER, name: 'Defaults with 12 months' },
  { display: TYPES.PERCENT, name: 'Delinquent accounts as % of balance' },
  { display: TYPES.CURRENCY, name: 'Value of current defaults' },
  { display: TYPES.NUMBER, name: 'Runaways (credit)' },
  { display: TYPES.NUMBER, name: 'Runaways (utilities)' },
  { display: TYPES.CURRENCY, name: 'Value of accounts on Debt Management' },
  { display: TYPES.CURRENCY, name: 'Value of accounts opened with 3 months' },
  { display: TYPES.NUMBER, name: 'Balances vs. new credit' },
  { display: TYPES.BOOL, name: 'Thin file 1' },
  { display: TYPES.BOOL, name: 'Thin file 2' },
  { display: TYPES.NUMBER, name: 'Accounts on repayment plan' },
  { display: TYPES.CURRENCY, name: 'Value of short term loans in debt' },
  { display: TYPES.CURRENCY, name: 'Value of satisfied CCJs' },
  { display: TYPES.NUMBER, name: 'Months since last CCJ' },
  { display: TYPES.NUMBER, name: 'Worsening credit (sector 1)' },
  { display: TYPES.NUMBER, name: 'Worsening credit (sector 2)' },
  { display: TYPES.BOOL, name: 'Worst payment' },
  { display: TYPES.BOOL, name: '<24 months since last default on HP' },
  { display: TYPES.CURRENCY, name: 'Value of active CCJs' },
  { display: TYPES.CURRENCY, name: 'Value of all CCJs' },
  { display: TYPES.NUMBER, name: 'CCJs within 12 months' },
  { display: TYPES.BOOL, name: 'Insolvent' },
  { display: TYPES.BOOL, name: 'Restricted' },
  { display: TYPES.BOOL, name: 'Notice of correction' },
  { display: TYPES.BOOL, name: 'Bankruptcy' },
  { display: TYPES.NUMBER, name: 'Aliases' },
  { display: TYPES.NUMBER, name: 'Credit checks within 3 months' },
  { display: TYPES.NUMBER, name: 'Debt collection searches' },
  { display: TYPES.NUMBER, name: 'Payment plans within 12 months' },
  { display: TYPES.NUMBER, name: 'Missed payments within 12 months' },
  { display: TYPES.NUMBER, name: 'Revolving accounts' },
  { display: TYPES.CURRENCY, name: 'Value of accounts in sector 10' },
  { display: TYPES.NUMBER, name: 'Payment status of short term loans' },
  { display: TYPES.NUMBER, name: 'Credit score' },
  { display: TYPES.PERCENT, name: 'Balance to limit ratio' },
  { display: TYPES.BOOL, name: 'Existing customer' },
  { display: TYPES.NUMBER, name: 'Number of insolvencies' },
  { display: TYPES.NUMBER, name: 'Electoral register' },
  { display: TYPES.NUMBER, name: 'Voluntary termination' },
  { display: TYPES.NUMBER, name: 'DRO or administration order' },
  { display: TYPES.CURRENCY, name: 'Short term loans (3 months)' },
  { display: TYPES.CURRENCY, name: 'Short term loans (3 - 6 months)' },
];
const CreditRiskCard = ({ onClick, report, className, display = 'combined' }: IProps) => {
  const passedFieldsCount = report && numberOfPassedFields(report.reportSummary);
  const failedFieldsCount = report && report.reportSummary.length - passedFieldsCount;

  const isDataAvailable = !!report;

  const hasMatchingLevel = isDataAvailable && report.matchingLevel;
  const isVisibleData = isDataAvailable && hasMatchingLevel;
  const indicatorStatus = isDataAvailable
    ? setStatus(report.isPositive, report.matchingLevel)
    : IndicatorStatus.NO_MATCH;

  if (display === 'full') {
    const credit = report.reportSummary
      .map((elem: any) => ({
        ...elem,
        ...creditValuesDisplayTypes.find((cr: any) => elem.name === cr.name),
      }))
      .filter(Boolean);

    return (
      <CardContainer
        onClick={onClick}
        size="full"
        status={indicatorStatus}
        title="Credit"
        comment="Credit profile data"
        className={cx(className, 'creditrisk', {})}
      >
        <div className="creditrisk_block">
          {credit.map((elem: any, idx: number) => (
            <div key={idx} className="creditrisk__item">
              <p className={`creditrisk__text ${elem.value === null ? 'grey' : ''}`}>{elem.name}</p>
              {elem.value === null && <p className={`creditrisk__text grey`}>N/A</p>}
              {elem.value !== null && (
                <p className={`creditrisk__text ${!elem.status ? 'green' : 'red'}`}>
                  {elem.display === TYPES.NUMBER
                    ? elem.value
                    : elem.display === TYPES.CURRENCY
                      ? formatMoney(elem.value)
                      : elem.display === TYPES.PERCENT
                        ? `${elem.value.toFixed(2)}%`
                        : elem.value
                          ? 'Yes'
                          : 'No'}
                </p>
              )}
            </div>
          ))}
        </div>
      </CardContainer>
    );
  }

  return (
    <CardContainer
      {...(isVisibleData && { onClick: onClick })}
      {...(isDataAvailable && { status: indicatorStatus })}
      title={'Credit'}
      comment={'Credit risk data'}
      className={cx(className, {
        disabled: !hasMatchingLevel,
        limited: display === 'limited',
      })}
    >
      {isDataAvailable && display !== 'limited' && (
        <>
          <h1 className="card__info">{report.overallScore}</h1>
          <div className="card__flex">
            <p className="card__text">Passes</p>
            <p className="card__value">{passedFieldsCount}</p>
          </div>
          <div className="card__flex">
            <p className="card__text">Fails</p>
            <p className="card__value">{failedFieldsCount}</p>
          </div>
        </>
      )}
    </CardContainer>
  );
};

export default CreditRiskCard;
