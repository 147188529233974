import React from 'react';
import CardContainer from '../../CardContainer/CardContainer';
import cx from 'classnames';
import './AffordabilityCard.scss';
import { formatMoney } from '../../../utils/formatMoney';
import { IndicatorStatus } from '../../Indicator/Indicator';

interface IProps {
  onClick?: () => void;
  className: string;
  report: any;
  display?: 'full' | 'combined' | 'limited';
}

const AffordabilityCard = ({ onClick, report, className, display = 'combined' }: IProps) => {
  const isDataExist = !!report;

  const { incomeSummary, expenditureSummary } = report?.data.budgetPlan || {};

  const incomeToHuman = {
    'Identified salary': incomeSummary?.income,
    'Additional income': incomeSummary?.cashIn + incomeSummary?.transfersIn,
    'Other income': incomeSummary?.otherIncome,
  };
  const expendituresToHuman = {
    'Rent/mortgage': expenditureSummary?.rent,
    Travel: expenditureSummary?.travel,
    'Bills & services': expenditureSummary?.bills,
    Food: expenditureSummary?.food,
    'Repayments on existing debts': expenditureSummary?.debt,
    'Regular transfers out': expenditureSummary?.transfersOut,
    'Takeaways & eating out': expenditureSummary?.takeaways,
    'Clothing & footwear': expenditureSummary?.clothing,
    'Repayments on this hire': expenditureSummary?.repayment,
  };

  const isGreen = incomeSummary?.total - expenditureSummary?.total >= 0;

  const status = isGreen ? IndicatorStatus.SUCCESS : IndicatorStatus.FAIL;

  if (display === 'full') {
    return (
      <CardContainer
        onClick={onClick}
        title={'Budget plan'}
        comment={'The applicant’s financial position'}
        className={cx(className, 'affordability', {})}
        status={status}
      >
        <div className="affordability__block">
          <div className="affordability__block_total-sum">
            <div className="affordability__flex">
              <p className="affordability__block_item">Income</p>
              <p className="affordability__block_item">{formatMoney(incomeSummary?.total)}</p>
            </div>
          </div>
          <div className="affordability__block_categories">
            {Object.entries(incomeToHuman).map(([key, value]: any, idx: number) => {
              if (!value) return;
              return (
                <div key={idx} className="affordability__flex">
                  <p className="affordability__block_item">{key}</p>
                  <p className="affordability__block_item">{formatMoney(value)}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="affordability__block">
          <div className="affordability__block_total-sum">
            <div className="affordability__flex">
              <p className="affordability__block_item">Expenditure</p>
              <p className="affordability__block_item">{formatMoney(expenditureSummary?.total)}</p>
            </div>
          </div>
          <div className="affordability__block_categories">
            {Object.entries(expendituresToHuman).map(([key, value]: any, idx: number) => {
              return (
                <div key={idx} className="affordability__flex">
                  <p className="affordability__block_item">{key}</p>
                  <p className="affordability__block_item">{formatMoney(value)}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="affordability__block">
          <div className="affordability__block_total-sum">
            <div className="affordability__flex">
              <p className="affordability__block_item">Budget</p>
              <p className={`affordability__block_item ${isGreen ? 'green' : 'red'}`}>
                {formatMoney(incomeSummary?.total - expenditureSummary?.total)}
              </p>
            </div>
          </div>
        </div>
      </CardContainer>
    );
  }
  return (
    <>
      <CardContainer
        {...(isDataExist && { onClick: onClick })}
        {...(isDataExist && { status })}
        title={'Budget plan'}
        comment={'AFFORDABILITY DATA'}
        className={cx(className, {
          limited: display === 'limited',
        })}
      >
        {isDataExist && display !== 'limited' && (
          <>
            <h1 className={`card__info ${isGreen ? 'green' : 'red'}`}>
              {formatMoney(incomeSummary?.total - expenditureSummary?.total)}
            </h1>
            <div className="card__flex">
              <p className="card__text">Income</p>
              <p className="card__text">{formatMoney(incomeSummary?.total)}</p>
            </div>
            <div className="card__flex">
              <p className="card__text">Expenditure</p>
              <p className="card__text">{formatMoney(expenditureSummary?.total)}</p>
            </div>
          </>
        )}
      </CardContainer>
    </>
  );
};

export default AffordabilityCard;
