//@ts-nocheck
import React, { useEffect } from 'react';
import EmailContainer from '../../../components/Comms/EmailContainer';
import './Comms.scss';
import { RootState, useAppDispatch } from '../../../app/store';
import { useAppSelector } from '../../../app/hooks';
import { EmailTemplateType, getAllEmails } from '../../../app/features/templates/templatesSlice';
import { ISelectOption } from '../../../components/CustomSelect/CustomSelect';
import { useOutletContext } from 'react-router-dom';
import { getCharsCount } from '../../../utils/getEditorCharsCount';
import { sendEmail, setIsSent } from '../../../app/features/comms/commsSlice';
import { formatToSelect } from '../../../utils/formatToSelect';

const EmailPage = () => {
  const [customer] = useOutletContext() as any;
  const dispatch = useAppDispatch();
  const editorRef: React.MutableRefObject<any | null> = React.useRef(null);
  const { emailList } = useAppSelector((state: RootState) => state.templates);
  const { isLoading, isSent } = useAppSelector((state: RootState) => state.communicationsReducer);
  const { user } = useAppSelector((state: RootState) => state.userReducer);

  const { signature } = user;

  const [useSignature, setUseSignature] = React.useState(true);
  const [content, setContent] = React.useState(signature);
  const [subject, setSubject] = React.useState('subject');
  const [templates, setTemplates] = React.useState<ISelectOption[]>([]);
  const [contentTouched, setContentTouched] = React.useState(false);
  const [selectedOption, setSelectedOption] = React.useState<ISelectOption | null>(null);


  function replacePlaceholders(html, replacements) {
    let newHtml = html;
    if (typeof html !== 'string') {
      throw new Error('html must be a string');
    }
    Object.keys(replacements).forEach(key => {
      const regex = new RegExp(`{{${key}}}`, 'g');
      newHtml = html.replace(regex, replacements[key]);
    });
    return newHtml;
  }

  const deleteSignature = () => {
    const totalChars = editorRef.current.getCharacterCount();
    const signatureChars = getCharsCount(signature);
    editorRef.current.deleteRange(totalChars - signatureChars, totalChars + 1);
  };
  useEffect(() => {
    if (isSent) {
      setTimeout(() => {
        dispatch(setIsSent(false));
        handleClear();
      }, 5000);
    }
  }, [isSent]);

  const addSignature = () => {
    editorRef.current.insertContentAt(editorRef.current.getCharacterCount() + 1, signature);
  };

  useEffect(() => {
    editorRef.current.insertContent(signature);
    if (!emailList.length) {
      dispatch(getAllEmails());
    }
  }, []);

  useEffect(() => {
    setTemplates(formatToSelect(emailList));
  }, [emailList]);
  const handleSelectedTemplate = (obj: ISelectOption) => {
    setSelectedOption(obj);

    let selectedTemplate = emailList.find((t: EmailTemplateType) => t.id === obj.value)?.htmlContent

    if (useSignature && signature) {
      selectedTemplate = `${selectedTemplate} ${signature}`
    }

    editorRef.current.clearContent();
    editorRef.current.insertContent(selectedTemplate);
    setSubject(selectedTemplate.name);
  };
  const handleSignature = (value: boolean) => {
    setUseSignature(value);
    value ? addSignature() : deleteSignature();
  };
  const handleSend = () => dispatch(sendEmail({ content: (content as any), subject }));

  const handleClear = () => {
    setSelectedOption(null);
    setContent(useSignature ? (signature as any) : null);
    setSubject('subject');
    setContentTouched(false);
    if (useSignature) {
      const totalChars = editorRef.current.getCharacterCount();
      const signatureChars = getCharsCount(signature);
      editorRef.current.deleteRange(0, totalChars - signatureChars);
    } else {
      editorRef.current.clearContent();
    }
  };

  const handleContentChanges = (content: string) => {
    setContent(content as any);
    setContentTouched(true);
  };

  return (
    <div className={`comms-page`}>
      <div className={`comms-page__container`}>
        <EmailContainer
          options={templates}
          subject={subject}
          setSubject={setSubject}
          selectedOption={selectedOption}
          setSelectedOption={handleSelectedTemplate}
          useSignature={useSignature}
          setUseSignature={handleSignature}
          handleCancel={handleClear}
          content={content}
          setContent={handleContentChanges}
          isLoading={isLoading}
          handleSend={handleSend}
          isSent={isSent}
          editorRef={editorRef}
          contentTouched={contentTouched}
          title={'Email.'}
          className={`comms-page__email-container`}
        />
      </div>
    </div>
  );
};

export default EmailPage;
