import React from 'react';
import cx from 'classnames';
import './DialogPopup.scss';

const DialogPopupWrapper = ({ title, className, children }: any) => {
  return (
    <div className={cx('dialog-popup', className)}>
      <h1 className="dialog-popup__title">{title}</h1>
      {children}
    </div>
  );
};

export default DialogPopupWrapper;
