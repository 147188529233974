import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {
  getLeadsAPI,
  getAppsAPI,
  getSuccessAPI,
  getPaidAPI,
  getFundedAPI,
  getChartDataAPI,
  getIntroducedAPI,
  getJourneyDataAPI,
} from './reportsAPI';
import { showToast } from '../ui/uiSlice';

export type ReportType = {
  userId: string;
  fullName: string;
  loanAmount: number;
  postCode: string;
  address: string;
  createdAt: string;
  agreementNumber: string | null;
  status: string;
  dob: string;
  reference: string;
  requestedLoanAmount?: number;
  apr?: number;
};

export type FundedReportType = {
  appDate: string;
  fundedDate: string;
  loanAmount: number;
  apr: number;
  reference: string;
};

export type JourneyType = {
  brokerIntro: number;
  openBankingConsent: number;
  openBankingProgress: number;
  connectAccount: number;
  loanPurpose: number;
  incomeChange: number;
  affordabilityQuestions: number;
  alternativeOffer: number;
  budgetPlan: number;
  agreement: number;
  directDebit: number;
};

export type SingleChartReportType = {
  leads: number;
  introduced?: number;
  applications: number;
  successes: number;
  declines: number;
  paid: number;
  paidAmount: number | null;
  total: number;
  date: string;
};

export type ChartReportsType = {
  cw: SingleChartReportType[] | [];
  direct: SingleChartReportType[] | [];
};

export type ReportsStateType = {
  isLoading: boolean;
  report: ReportType[] | [];
  funded: FundedReportType[];
  chart: ChartReportsType;
  journey: JourneyType;
  error: string;
};

export const reportsSlice = createSlice({
  name: 'reports',
  initialState: {
    isLoading: false,
    report: [],
    funded: [],
    journey: {
      brokerIntro: 0,
      openBankingConsent: 0,
      openBankingProgress: 0,
      connectAccount: 0,
      loanPurpose: 0,
      incomeChange: 0,
      affordabilityQuestions: 0,
      alternativeOffer: 0,
      budgetPlan: 0,
      agreement: 0,
      directDebit: 0,
    },
    chart: {
      cw: [],
      direct: [],
    },
    error: '',
  } as ReportsStateType,
  reducers: {
    clearReport: (state) => {
      state.report = [];
      state.funded = [];
      state.journey = {
        brokerIntro: 0,
        openBankingConsent: 0,
        openBankingProgress: 0,
        connectAccount: 0,
        loanPurpose: 0,
        incomeChange: 0,
        affordabilityQuestions: 0,
        alternativeOffer: 0,
        budgetPlan: 0,
        agreement: 0,
        directDebit: 0,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLeads.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getLeads.fulfilled, (state, { payload }) => {
      state.report = payload;
      state.isLoading = false;
    });
    builder.addCase(getLeads.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getApps.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getApps.fulfilled, (state, { payload }) => {
      state.report = payload;
      state.isLoading = false;
    });
    builder.addCase(getApps.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getIntroduced.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getIntroduced.fulfilled, (state, { payload }) => {
      state.report = payload;
      state.isLoading = false;
    });
    builder.addCase(getIntroduced.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getSuccess.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getSuccess.fulfilled, (state, { payload }) => {
      state.report = payload;
      state.isLoading = false;
    });
    builder.addCase(getSuccess.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getPaid.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getPaid.fulfilled, (state, { payload }) => {
      state.report = payload;
      state.isLoading = false;
    });
    builder.addCase(getPaid.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getFunded.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFunded.fulfilled, (state, { payload }) => {
      state.funded = payload;
      state.isLoading = false;
    });
    builder.addCase(getFunded.rejected, (state) => {
      state.isLoading = false;
    });

    builder.addCase(getChartData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getChartData.fulfilled, (state, { payload }) => {
      state.chart = payload;
      state.isLoading = false;
    });
    builder.addCase(getChartData.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getJourneyData.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getJourneyData.fulfilled, (state, { payload }) => {
      state.journey = payload;
      state.isLoading = false;
    });
    builder.addCase(getJourneyData.rejected, (state) => {
      state.isLoading = false;
    });
  },
});

export const getLeads = createAsyncThunk(
  'report/getLeads',
  async ({ startDate, endDate }: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await getLeadsAPI(startDate, endDate);
      return response.data;
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const getApps = createAsyncThunk(
  'report/getApps',
  async ({ startDate, endDate }: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await getAppsAPI(startDate, endDate);
      return response.data;
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const getIntroduced = createAsyncThunk(
  'report/getIntroduced',
  async ({ startDate, endDate }: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await getIntroducedAPI(startDate, endDate);
      return response.data;
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const getSuccess = createAsyncThunk(
  'report/getSuccess',
  async ({ startDate, endDate }: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await getSuccessAPI(startDate, endDate);
      return response.data;
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const getPaid = createAsyncThunk(
  'report/getPaid',
  async ({ startDate, endDate }: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await getPaidAPI(startDate, endDate);
      return response.data;
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
      return rejectWithValue(err.response.data.message);
    }
  }
);
export const getFunded = createAsyncThunk(
  'report/getFunded',
  async ({ startDate, endDate }: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await getFundedAPI(startDate, endDate);
      return response.data;
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const getChartData = createAsyncThunk(
  'report/getChartData',
  async ({ startDate, endDate }: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await getChartDataAPI(startDate, endDate);
      return response.data;
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const getJourneyData = createAsyncThunk(
  'report/getJourneyData',
  async ({ startDate, endDate }: any, { dispatch, rejectWithValue }) => {
    try {
      const response = await getJourneyDataAPI(startDate, endDate);
      return response.data;
    } catch (err: any) {
      dispatch(
        showToast({
          message: err.response.data.message,
          severity: 'failed',
        })
      );
      return rejectWithValue(err.response.data.message);
    }
  }
);

export const { clearReport } = reportsSlice.actions;

export default reportsSlice.reducer;
