//@ts-nocheck
import React from 'react';
import Select, { ISelectOption } from '../../CustomSelect/CustomSelect';
import CardContainer from '../CardContainer/CardContainer';
import './ApplicantData.scss';
import Input from '../../input/Input';
import { Field, Form, Formik } from 'formik';
import cx from 'classnames';
import InputWithBtn from '../../InputWithBtn/InputWithBtn';
import * as Yup from 'yup';
import BtnGroup from '../../BtnGroup/BtnGroup';
import { sortcodeToBank } from '../../../utils/bankCheck';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { getChangedValues } from '../../../utils/getChangedValues';
import { setSuccess, updateProfile } from '../../../app/features/customers/customersSlice';
import useAddressFetch from '../../../hooks/useAddressFetch';

type AddressResponseType = {
  [key: string]: string;
};

const formatAddressForSelect = (arr: AddressResponseType[]): ISelectOption[] => {
  return arr.map((elem, idx) => {
    return {
      value: idx,
      label: elem.addressFull,
    };
  });
};

interface IApplicantData {
  mobileNumber: string;
  emailAddress: string;
  postCode: string;
  sortCode: string | null;
  accountNumber: string | null;
  prevPostCode: string | '';
}

const ApplicantData = ({
  mobileNumber,
  emailAddress,
  currentAddress,
  bankDetails,
  previousAddress,
}: any) => {
  const ValidationSchema = Yup.object().shape({
    mobileNumber: Yup.string()
      .transform((value) => value.replace(/\s/g, ''))
      .min(11)
      .max(11)
      .required('required'),
    emailAddress: Yup.string().email('Invalid email').required('required'),
    accountNumber: Yup.string().min(8).max(8).required('required'),
    sortCode: Yup.string()
      .transform((value) => value.replace(/[-]/g, ''))
      .min(6)
      .max(6)
      .required('required'),
    postCode: Yup.string().min(3).max(11).required('required'),
    prevPostCode: Yup.string().min(3).max(11).nullable(),
  });

  let sortCode = '';
  let accountNumber = '';

  if (bankDetails.length) {
    sortCode = bankDetails[0].sortCode;
    accountNumber = bankDetails[0].accountNumber;
  }

  const dispatch = useAppDispatch();

  const { isModalLoading, isSuccess } = useAppSelector((state) => state.customers);

  React.useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        dispatch(setSuccess(false));
      }, 5000);
    }
  }, [isSuccess]);

  const [selectedCurrentAddressIndex, setSelectedCurrentAddressIndex] = React.useState<
    number | null
  >(null);
  const [selectedPreviousAddressIndex, setSelectedPreviousAddressIndex] = React.useState<
    number | null
  >(null);

  const [selectedCurrentAddress, setSelectedCurrentAddress] = React.useState(null);
  const [selectedPreviousAddress, setSelectedPreviousAddress] = React.useState(null);

  const {
    currentAddresses,
    previousAddresses,
    isLoading: isLoadingAddresses,
    clearState,
    fetchAddresses,
  } = useAddressFetch();

  const handleCurrentAddressSelect = (selectedIdx: number) => {
    setSelectedCurrentAddressIndex(selectedIdx);
  };

  const handlePreviousAddressSelect = (selectedIdx: number) => {
    setSelectedPreviousAddressIndex(selectedIdx);
  };

  const handleCancelClick = () => {
    setSelectedCurrentAddressIndex(null);
    setSelectedCurrentAddressIndex(null);
    clearState();
  };

  const formattedCurrAddress = formatAddressForSelect(currentAddresses);
  const formattedPrevAddress = formatAddressForSelect(previousAddresses);

  const formikInitialValues = {
    mobileNumber,
    emailAddress,
    postCode: currentAddress?.postCode,
    prevPostCode: previousAddress?.postCode || '',
    sortCode,
    accountNumber,
  };

  const handleSubmit = (values: any, { resetForm }: any) => {
    const changes = getChangedValues(formikInitialValues, values);
    const addressChanges = {};
    const bankDetailsChanges = {};
    const profileChanges = {};

    if (selectedCurrentAddressIndex) {
      addressChanges['current'] = selectedCurrentAddress;
    }
    if (selectedPreviousAddressIndex) {
      addressChanges['previous'] = selectedPreviousAddress;
    }
    Object.keys(changes).forEach((key) => {
      if (key === 'postCode' || key === 'prevPostCode') {
        return;
      } else if (key === 'sortCode' || key === 'accountNumber') {
        bankDetailsChanges[key] = changes[key];
        bankDetailsChanges['bankName'] = sortcodeToBank(values.sortCode);
      } else {
        profileChanges[key] = changes[key];
      }
    });

    dispatch(updateProfile({ addressChanges, bankDetailsChanges, profileChanges }));
  };

  const formikConfig = {
    initialValues: formikInitialValues,
    validationSchema: ValidationSchema,
    onSubmit: () => {},
  };

  return (
    <CardContainer className="applicant-data" size={'lg'} comment={'Application data'}>
      <div className="applicant-data__container">
        <Formik {...formikConfig}>
          {({
            errors,
            handleChange,
            values,
            touched,
            resetForm,
            initialValues,
            dirty,
            isSubmitting,
            submitForm,
            isValid,
            setFieldValue,
          }) => {
            return (
              <Form className="applicant-data__form form" autoComplete="off">
                <Field
                  id="mobileNumber"
                  name="mobileNumber"
                  mask={'99999 999 999'}
                  maskChar=""
                  alwaysShowMask={true}
                  onChange={handleChange('mobileNumber')}
                  error={touched.mobileNumber && errors.mobileNumber}
                  className={cx({
                    ['form__field']: true,
                    error: touched.mobileNumber && errors.mobileNumber,
                  })}
                  as={Input}
                />
                <Field
                  id="emailAddress"
                  name="emailAddress"
                  onChange={handleChange('emailAddress')}
                  error={touched.emailAddress && errors.emailAddress}
                  className={cx({
                    ['form__field']: true,
                    error: touched.emailAddress && errors.emailAddress,
                  })}
                  as={Input}
                />
                {currentAddresses.length ? (
                  <Field
                    id="postCode"
                    name="postCode"
                    onChange={(e: ISelectOption) => {
                      handleCurrentAddressSelect(e.value as number);
                      const selectedAddress = currentAddresses.find((elem, idx) => idx === e.value);
                      setSelectedCurrentAddress(selectedAddress);
                      setFieldValue('postCode', selectedAddress.postcode);
                    }}
                    optionsList={formattedCurrAddress}
                    selectedOption={formattedCurrAddress.filter(
                      (o) => o.value === selectedCurrentAddressIndex
                    )}
                    className={'form__field'}
                    as={Select}
                  />
                ) : (
                  <Field
                    id="postCode"
                    name="postCode"
                    onChange={handleChange('postCode')}
                    error={touched.postCode && errors.postCode}
                    onClick={() => fetchAddresses(values.postCode)}
                    children={'Search'}
                    className={'form__field'}
                    as={InputWithBtn}
                  />
                )}
                {previousAddresses.length ? (
                  <Field
                    id="prevPostCode"
                    name="prevPostCode"
                    onChange={(e: ISelectOption) => {
                      handlePreviousAddressSelect(e.value as number);
                      const selectedAddress = previousAddresses.find(
                        (elem, idx) => idx === e.value
                      );
                      setSelectedPreviousAddress(selectedAddress);
                      setFieldValue('prevPostCode', selectedAddress.postcode);
                    }}
                    optionsList={formattedPrevAddress}
                    selectedOption={formattedPrevAddress.filter(
                      (o) => o.value === selectedPreviousAddressIndex
                    )}
                    className={'form__field'}
                    as={Select}
                  />
                ) : (
                  <Field
                    id="prevPostCode"
                    name="prevPostCode"
                    onChange={handleChange('prevPostCode')}
                    error={touched.prevPostCode && errors.prevPostCode}
                    onClick={() => fetchAddresses(values.prevPostCode, true)}
                    children={'Search'}
                    className={'form__field'}
                    as={InputWithBtn}
                  />
                )}
                <Field
                  id="sortCode"
                  name="sortCode"
                  onChange={handleChange('sortCode')}
                  error={touched.sortCode && errors.sortCode}
                  mask={'99-99-99'}
                  maskChar=""
                  alwaysShowMask={true}
                  className={cx({
                    ['form__field']: true,
                    error: touched.sortCode && errors.sortCode,
                  })}
                  as={Input}
                />
                <Field
                  id="accountNumber"
                  name="accountNumber"
                  mask={'99999999'}
                  maskChar=""
                  alwaysShowMask={true}
                  onChange={handleChange('accountNumber')}
                  error={touched.accountNumber && errors.accountNumber}
                  className={cx({
                    ['form__field']: true,
                    error: touched.accountNumber && errors.accountNumber,
                  })}
                  as={Input}
                />
                <div className="applicant-data__details details">
                  <p className="details__item">{sortcodeToBank(values.sortCode)}</p>
                </div>
                <BtnGroup
                  className={'applicant-data__btn-block'}
                  isActive={
                    dirty ||
                    isSubmitting ||
                    !!selectedCurrentAddressIndex ||
                    !!selectedPreviousAddressIndex
                  }
                  onClick={() => {
                    handleSubmit(values, formikInitialValues);
                  }}
                  onCancel={() => {
                    handleCancelClick();
                    resetForm();
                  }}
                  isLoading={isModalLoading}
                  isSuccess={isSuccess}
                />
              </Form>
            );
          }}
        </Formik>
      </div>
    </CardContainer>
  );
};

export default ApplicantData;
