import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { updateUserAPI } from './userAPI';
import { showToast } from '../ui/uiSlice';

export type SignatureType = {
  type: string;
  content: any[];
};

type SliceState = {
  isLoading: boolean;
  error: null | string;
  isSuccess: boolean;
  user: {
    id: string;
    fullName: string;
    emailAddress: string;
    signature: null | SignatureType;
  };
};

const initialState: SliceState = {
  isLoading: false,
  error: null,
  isSuccess: false,
  user: {
    id: '',
    fullName: '',
    emailAddress: '',
    signature: null,
  },
};

const slice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUser: (state, { payload }) => {
      state.user = { ...state.user, ...payload, signature: payload?.htmlSignature || '' };
    },
    setSuccess: (state, { payload }) => {
      state.isSuccess = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateUser.pending, (state) => {
      state.isSuccess = false;
      state.isLoading = true;
    });
    builder.addCase(updateUser.fulfilled, (state, { payload }) => {
      state.user = { ...payload, signature: payload?.htmlSignature || '' };
      state.isSuccess = true;
      state.isLoading = false;
    });
    builder.addCase(updateUser.rejected, (state, { payload }: any) => {
      state.error = payload.error;
      state.isLoading = false;
    });
  },
});

export const updateUser = createAsyncThunk(
  'user/updateUser',
  async (data: any, { rejectWithValue, dispatch }) => {
    try {
      const response = await updateUserAPI(data);
      if (response.status === 200) {
        dispatch(
          showToast({
            message: 'Profile updated',
            severity: 'success',
          })
        );
      }
      return response.data;
    } catch (err: any) {
      if (err.response.status !== 500) {
        dispatch(
          showToast({
            message: err.response.data.message,
            severity: 'failed',
          })
        );
      } else {
        dispatch(
          showToast({
            message: 'Something went wrong :(',
            severity: 'failed',
          })
        );
      }
      return rejectWithValue({
        error: err.response.data.message,
      });
    }
  }
);

export const { setUser, setSuccess } = slice.actions;

export const userReducer = slice.reducer;
