import React, { useEffect, useState } from 'react';
import CustomersTableContainer, {
  DateType,
} from '../../components/CustomersTableContainer/CustomersTableContainer';
import { format } from 'date-fns';
import { useLocation, useParams } from 'react-router-dom';
import { getReportsAPI } from '../../app/features/reports/reportsAPI';

const ReportTable = ({ title, reportType }: any) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [report, setReport] = useState<any>([]);

  const { key } = useLocation();

  const today = new Date();
  const [date, selectedDate] = useState<DateType>({
    rangeName: 'Today',
    from: today,
    to: today,
  });

  let name = 'Today';

  if (date && date.rangeName) name = date.rangeName;

  useEffect(() => {
    const makeRequest = async () => {
      setIsLoading(true);
      const response = await getReportsAPI(
        reportType,
        format(date.from, 'dd/MM/y'),
        format(date.to, 'dd/MM/y')
      );
      setReport(response.data);
      setIsLoading(false);
    };
    makeRequest();

    return () => {
      setReport([]);
    };
  }, [date, key]);
  const handleSelectedDate = (date: DateType) => selectedDate(date);

  return (
    <CustomersTableContainer
      isLoading={isLoading}
      title={title}
      data={report}
      date={date}
      selectedDate={handleSelectedDate}
      dropdownName={name}
    />
  );
};

export default ReportTable;
