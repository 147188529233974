import subDays from 'date-fns/subDays';
import { format, parse, subMonths } from 'date-fns';

const today = new Date();

const ranges = [
  {
    name: 'Today',
    range: {
      from: today,
      to: today,
    },
  },
  {
    name: 'Last 7 days',
    range: {
      from: subDays(today, 7),
      to: today,
    },
  },
  {
    name: 'Last 14 days',
    range: {
      from: subDays(today, 14),
      to: today,
    },
  },
  {
    name: 'Last 30 days',
    range: {
      from: subDays(today, 30),
      to: today,
    },
  },
  {
    name: 'Last 3 months',
    range: {
      from: subMonths(today, 3),
      to: today,
    },
  },
  {
    name: 'Last 12 months',
    range: {
      from: subMonths(today, 12),
      to: today,
    },
  },
  {
    name: 'Month to date',
    range: {
      from: parse('01/' + format(today, 'MM/y'), 'dd/MM/y', today),
      to: today,
    },
  },
  {
    name: 'Quarter to date',
    range: {
      from: parse(format(today, 'Q'), 'Q', today),
      to: today,
    },
  },
  {
    name: 'All time',
    range: {
      from: parse('01/01/2021', 'dd/LL/y', today),
      to: today,
    },
  },
  {
    name: 'Custom',
    range: {
      from: today,
      to: today,
    },
  },
];

export default ranges;
