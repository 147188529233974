import CustomAxios from '../../customAxios/CustomAxios';

export const getUsersAPI = () => {
  return CustomAxios.get(`${process.env.REACT_APP_USERS_URL}/admins`);
};

export const addUserAPI = (data: any) => {
  return CustomAxios.post(`${process.env.REACT_APP_USERS_URL}/admins`, data);
};

export const deleteUserAPI = (id: string) => {
  return CustomAxios.delete(`${process.env.REACT_APP_USERS_URL}/admins/${id}`);
};

export const resetPasswordAPI = (id: string) => {
  return CustomAxios.get(`${process.env.REACT_APP_USERS_URL}/admins/reset-password/${id}`);
};
