// @ts-ignore
export function replacePlaceholders(obj, replacement) {
  if (typeof obj === 'string') {
    // Use {{key}} as the placeholder pattern
    return obj.replace(/{{(.*?)}}/g, (match, key) => replacement[key] || match);
  } else if (Array.isArray(obj)) {
    return obj.map((item) => replacePlaceholders(item, replacement));
  } else if (typeof obj === 'object' && obj !== null) {
    const newObj = {};
    for (const key in obj) {
      // @ts-ignore
      newObj[key] = replacePlaceholders(obj[key], replacement);
    }
    return newObj;
  } else {
    return obj;
  }
}
