import React, { useEffect } from 'react';
import CustomersTable from '../../components/CustomersTable/CustomersTable';
import { useLocation } from 'react-router-dom';
import { searchCustomersAPI } from '../../app/features/customers/customersAPI';

const SearchResultsTable = () => {
  const [data, setData] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const query = useLocation().search.split('?q=')[1];

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      const { data } = await searchCustomersAPI(query);
      setData(data);
      setIsLoading(false);
    };
    fetchData();
  }, []);

  return (
    <div className="search-results-table">
      <div className="header">
        <h1 className="title">Search results for {decodeURI(query)}</h1>
      </div>
      <div className="table">
        <CustomersTable isLoading={isLoading} customers={data} />
      </div>
    </div>
  );
};

export default SearchResultsTable;
