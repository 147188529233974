import React from 'react';
import CardContainer from '../../CardContainer/CardContainer';
import cx from 'classnames';
import creditstyles from '../../../static/creditstyles.xslt';
import './CallReportCard.scss';
import { IndicatorStatus } from '../../Indicator/Indicator';

interface IProps {
  onClick?: () => void;
  className: string;
  report: any;
  display?: 'full' | 'combined' | 'limited';
}

const setStatus = (result: boolean, match: boolean) => {
  if (!result) return match ? IndicatorStatus.FAIL : IndicatorStatus.NO_MATCH;
  return IndicatorStatus.SUCCESS;
};

const CallReportCard = ({ onClick, report, className, display = 'combined' }: IProps) => {
  const rootElement = document.createElement('div');

  const isDataExist = !!report?.report.rawData;

  const applicantPresent = report?.isApplicantPresent;

  if (isDataExist && applicantPresent) {
    let xsltProcessor = new XSLTProcessor();
    const parser = new DOMParser();

    let xslStylesheet;
    const myXMLHTTPRequest = new XMLHttpRequest();
    myXMLHTTPRequest.open('GET', creditstyles, false);
    myXMLHTTPRequest.send(null);
    xslStylesheet = myXMLHTTPRequest.responseXML;
    xsltProcessor.importStylesheet(xslStylesheet as any);

    const xml_doc = parser.parseFromString(
      JSON.parse(report.report.rawData).replace(
        'xmlns="urn:callcredit.co.uk/soap:bsbandcreditreport7"',
        ''
      ) as any,
      'application/xml'
    );

    const fragment = xsltProcessor.transformToFragment(xml_doc as any, document);

    rootElement.appendChild(fragment);
  }
  const {
    totalopened6months,
    worsepaystatus12months,
    totaldelinqs12months,
    worsepaystatus36months,
    totaldefaults12months,
    totaldefaults36months,
  } = report?.report?.shareInfo || {};

  const isMatch = isDataExist && report?.applicantCreditData.matchingLevel;
  const isVisible = isDataExist && applicantPresent;
  const status = isMatch
    ? setStatus(report?.applicantCreditData.isPositive, report?.applicantCreditData.matchingLevel)
    : IndicatorStatus.NO_MATCH;

  if (display === 'full') {
    return (
      <CardContainer
        onClick={onClick}
        size={'full'}
        title={'Credit'}
        comment={'Credit profile data'}
        className={cx(className, 'callreport', {})}
        status={status}
      >
        <div className="callreport__block">
          <div
            dangerouslySetInnerHTML={{
              __html: (rootElement as any).innerHTML,
            }}
          />
        </div>
      </CardContainer>
    );
  }

  return (
    <CardContainer
      {...(isVisible && { onClick: onClick })}
      {...(isDataExist && { status })}
      title={'Credit report'}
      comment={'CallReport'}
      className={cx(className, {
        disabled: !isMatch,
        limited: display === 'limited',
      })}
    >
      {isDataExist && display !== 'limited' && (
        <>
          <div className="card__flex">
            <p className="card__text">Accounts opened (6 months)</p>
            <p className="card__value">{totalopened6months}</p>
          </div>
          <div className="card__flex">
            <p className="card__text">Delinquent accounts (12 months)</p>
            <p
              className={`card__value 
                ${totaldelinqs12months > 0 && 'red'}`}
            >
              {totaldelinqs12months}
            </p>
          </div>
          <div className="card__flex">
            <p className="card__text">Worst payment status (12 months)</p>
            <p
              className={`card__value 
                ${
                  worsepaystatus12months > 0 && worsepaystatus12months < 3
                    ? 'amber'
                    : worsepaystatus12months > 2 || worsepaystatus12months === 'D'
                      ? 'red'
                      : ''
                }`}
            >
              {worsepaystatus12months}
            </p>
          </div>
          <div className="card__flex">
            <p className="card__text">Worst payment status (36 months)</p>
            <p
              className={`card__value 
                ${
                  worsepaystatus36months > 0 && worsepaystatus36months < 3
                    ? 'amber'
                    : worsepaystatus36months > 2 || worsepaystatus36months === 'D'
                      ? 'red'
                      : ''
                }`}
            >
              {worsepaystatus36months}
            </p>
          </div>
          <div className="card__flex">
            <p className="card__text">Defaults (12 months)</p>
            <p
              className={`card__value 
                ${totaldefaults12months > 0 && 'red'}`}
            >
              {totaldefaults12months}
            </p>
          </div>
          <div className="card__flex">
            <p className="card__text">Defaults (36 months)</p>
            <p
              className={`card__value 
                ${totaldefaults36months > 0 && 'red'}`}
            >
              {totaldefaults36months}
            </p>
          </div>
        </>
      )}
    </CardContainer>
  );
};

export default CallReportCard;
