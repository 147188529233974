import React from 'react';
import './JourneyPage.scss';
import metro from '../../assets/metro.png';
import Title from '../../components/Title/Title';
import { addDashes } from '../../utils/addDashes';
import Item from './Item';
import { useOutletContext } from 'react-router-dom';

const JourneyPage = () => {
  const [customer, reports] = useOutletContext() as any;

  const affordability: any = reports.affordability;

  const { questions } = affordability?.data || {};

  if (!questions) {
    return <div></div>;
  }

  function formatBanks(banks: any[]) {
    return banks
      .map(({ bankName, accountNumber, sortCode }: any) =>
        [bankName, accountNumber, sortCode].join(', ')
      )
      .join('\n');
  }

  const journey = [
    {
      name: 'Direct application',
      initialValue: '',
      value: '',
      asked: true,
    },
    {
      name: 'Broker application',
      initialValue: '',
      value: 'Choose Wisely',
      asked: false,
    },
    {
      name: 'Accounts connected',
      initialValue: '',
      value: formatBanks(customer.bankDetails),
      asked: true,
    },
    ...questions,
  ];

  return (
    <div className="metro">
      <Title text={customer.firstName + ' ' + customer.lastName + '.'} />
      <div className="img-wrapper">
        <img src={metro} alt="" />
        {journey.map((elem, idx): any => {
          return (
            <Item
              key={idx}
              name={elem.name}
              value={elem.value}
              reason={elem.reason}
              initialValue={elem.initialValue}
              asked={elem.asked}
            />
          );
        })}
      </div>
    </div>
  );
};

export default JourneyPage;
