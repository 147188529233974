import { AuthData } from '../../../pages/LoginPage/LoginPage';
import CustomAxios from '../../customAxios/CustomAxios';

export const signInAPI = (data: AuthData): Promise<any> => {
  return CustomAxios.post(`${process.env.REACT_APP_AUTH_URL}/auth/signin`, data);
};
export const signOutAPI = (): Promise<any> => {
  return CustomAxios.get(`${process.env.REACT_APP_AUTH_URL}/auth/signout`);
};

export const getMeAPI = (): Promise<any> => {
  return CustomAxios.get(`${process.env.REACT_APP_AUTH_URL}/auth/validate`);
};

export const refreshTokenAPI = (): Promise<any> => {
  return CustomAxios.get(`${process.env.REACT_APP_AUTH_URL}/auth/refresh`);
};
