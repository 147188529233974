import React, { useEffect, useState } from 'react';
import Title from '../../../components/Title/Title';
import './Events.scss';
import { RootState, useAppDispatch } from '../../../app/store';
import { useAppSelector } from '../../../app/hooks';
import { getEvents } from '../../../app/features/events/eventsSlice';
import { useParams } from 'react-router-dom';
import Loader from '../../../components/Loader/Loader';
import capitalizeFirstLetter from '../../../utils/capitalizeFirstLetter';
import { getEmailApi, getSMSApi } from '../../../app/features/comms/commsAPI';
import { format, parseISO } from 'date-fns';
import CommunicationModal from '../../../components/modals/CommunicationModal/CommunicationModal';

const formatString = (str: string) => {
  if (str.indexOf(' ') >= 0) return str;
  const lowerStr = str.toLowerCase();
  if (lowerStr === 'sms') {
    return lowerStr.toUpperCase();
  }
  return capitalizeFirstLetter(lowerStr);
};

const Events = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { isLoading, events } = useAppSelector((state: RootState) => state.events);

  const [showModal, setShowModal] = useState(false);
  const [modalType, setModalType] = useState<'sms' | 'email'>('email');
  const [data, setData] = useState<null | Object>(null);
  const [isFetching, setIsFetching] = useState(false);

  useEffect(() => {
    if (id) dispatch(getEvents(id));
  }, [dispatch, id]);

  const fetchData = async (itemId: number, type: 'sms' | 'email') => {
    if (id) {
      try {
        setIsFetching(true);
        const apiFunction = type === 'email' ? getEmailApi : getSMSApi;
        const fetchedData = await apiFunction(id, itemId);
        setData(fetchedData.data);
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle error appropriately
      } finally {
        setIsFetching(false);
      }
    }
  };

  const handleModal = (itemId: number, type: 'sms' | 'email') => {
    setModalType(type);
    fetchData(itemId, type);
    setShowModal(true);
  };

  if (isLoading) return <Loader />;

  return (
    <div className="events">
      <Title text={'Events.'} />
      <div className="events__table-container">
        <table className="events__table table">
          <thead className="table__head">
            <tr className="table__row">
              <th>Event</th>
              <th>Media</th>
              <th>User</th>
              <th>Status</th>
              <th>Date & time</th>
            </tr>
          </thead>
          <tbody className="table__body">
            {events.map((elem: any) => {
              return (
                <tr key={elem.id} className="table__row">
                  <td>
                    {elem.type === 'data' ? (
                      formatString(elem.name)
                    ) : (
                      <button onClick={() => handleModal(elem.details.id, elem.type)}>
                        {formatString(elem.name)}
                      </button>
                    )}
                  </td>
                  <td>{formatString(elem.type)}</td>
                  <td>{formatString(elem.admin ? elem.admin.fullName : 'system')}</td>
                  <td>{formatString(elem.status)}</td>
                  <td>{format(parseISO(elem.createdAt), 'HH:mm dd/MM/y')}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {isFetching ? (
        <Loader />
      ) : (
        <CommunicationModal
          show={showModal}
          onClose={setShowModal}
          modalType={modalType}
          data={data}
        />
      )}
    </div>
  );
};

export default React.memo(Events);
