import React from 'react';
import ReactDOM from 'react-dom';
import './ChainedModal.scss';
import { Fade } from 'react-awesome-reveal';

const ChainedModalComponent = (props: any) => {
  const { elements } = props;

  const [currentModal, setCurrentModal] = React.useState(0);
  const [totalModals, setTotalModals] = React.useState(elements.length || 0);

  const showNext = () => {
    if (currentModal < totalModals - 1) {
      setCurrentModal(currentModal + 1);
    }
  };

  const closeModal = () => {
    props.onRequestClose();
  };

  const showPrev = (idx = 1) => {
    if (currentModal > 0) {
      setCurrentModal(currentModal - idx);
    }
  };

  const returnWrappedElement = (El: any, props: any, index: number) => {
    if (currentModal === index)
      return (
        <Fade>
          <div key={index}>
            <El
              closeChainedModal={closeModal}
              className="wrapped-element"
              currentModal={currentModal}
              totalModals={totalModals}
              showNext={showNext}
              showPrev={showPrev}
              {...props}
            />
          </div>
        </Fade>
      );
  };

  return (
    <>
      {(props.visible &&
        ReactDOM.createPortal(
          <div
            className="react-chained-modal-cover"
            id="react-chained-modal-portal"
            onClick={(e: any) => {
              if (e.target.id === 'react-chained-modal-portal' && props.closeOnBackground) {
                props.onRequestClose();
              }
            }}
          >
            {props.elements.length &&
              props.elements.map((item: any, index: number) => {
                return returnWrappedElement(item.component, item.props, index);
              })}
          </div>,
          document.body
        )) ||
        null}
    </>
  );
};

const ChainedModal = (props: any) => {
  if (props.visible) {
    return <ChainedModalComponent {...props} />;
  } else {
    return null;
  }
};

export default ChainedModal;
