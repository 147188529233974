import React, { ReactNode, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { Fade } from 'react-awesome-reveal';

type ModalProps = {
  show: boolean;
  onClose: () => void;
  closeOnBackground?: boolean;
  children: ReactNode;
};

const Modal: React.FC<ModalProps> = ({ show, children, closeOnBackground = true, onClose }) => {
  useEffect(() => {
    const handleBodyStyle = () => {
      if (show) {
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = '15px';
      } else {
        document.body.style.overflow = 'unset';
        document.body.style.paddingRight = '0px';
      }
    };

    handleBodyStyle();

    return () => {
      handleBodyStyle();
    };
  }, [show]);

  if (!show) return null;

  return ReactDOM.createPortal(
    <div
      id="react-chained-modal-portal"
      className="react-chained-modal-cover"
      onClick={(e) => {
        // @ts-ignore
        if (closeOnBackground && e.target.id === 'react-chained-modal-portal') {
          onClose();
        }
      }}
    >
      <div className="overlay" onClick={onClose} />
      <Fade>{children}</Fade>
    </div>,
    document.body
  );
};

export default Modal;
