import React from 'react';
import ChainedModal from '../../ChainedModal/ChainedModal';
import DialogPopupWrapper from '../../DialogPopup/DialogPopup';
import BtnGroup from '../../BtnGroup/BtnGroup';
import Button from '../../Button/Button';
import '../DialogModal.scss';
import { useAppDispatch } from '../../../app/hooks';
import {
  deleteEmailTemplate,
  deleteSmsTemplate,
} from '../../../app/features/templates/templatesSlice';

interface DeleteConfirmationModalProps {
  type: string;
  id: number | null;
  onConfirm: () => void;
  onCancel: () => void;
}

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = ({
  type,
  id,
  onConfirm,
  showNext,
  onCancel,
}: any) => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    if (type === 'sms') {
      dispatch(deleteSmsTemplate(id!));
    }
    if (type === 'email') {
      dispatch(deleteEmailTemplate(id!));
    }
    showNext();
  };

  return (
    <DialogPopupWrapper title="Warning" className="modal">
      <div className="modal__body">
        <p>Are you sure you wish to delete this template?</p>
        <BtnGroup
          className="modal__btn"
          activeBtnName="OK"
          onCancel={onCancel}
          onClick={handleClick}
        />
      </div>
    </DialogPopupWrapper>
  );
};

const DeletionSuccessModal: React.FC = (props: any) => {
  return (
    <DialogPopupWrapper title="Warning" className="modal">
      <div className="modal__body">
        <p className="modal__text">Template deleted.</p>
        <Button onClick={() => props.closeChainedModal()} className="modal__btn">
          OK
        </Button>
      </div>
    </DialogPopupWrapper>
  );
};

interface WarningTemplateModalProps {
  onClose: () => void;
  visible: boolean;
  type: string | null;
  id: number | null;
}

const WarningTemplateModal: React.FC<WarningTemplateModalProps> = ({
  onClose,
  visible,
  type,
  id,
}) => {
  return (
    <ChainedModal
      visible={visible}
      onRequestClose={onClose}
      closeOnBackground
      elements={[
        {
          component: DeleteConfirmationModal,
          props: {
            type,
            id,
          },
        },
        {
          component: DeletionSuccessModal,
        },
      ]}
    />
  );
};

export default WarningTemplateModal;
