import React from 'react';
import { Route } from 'react-router-dom';
import Dashboard from '../pages/Dashboard/Dashboard';
import ReportTable from '../pages/ReportTable/ReportTable';
import CustomersLayout from '../components/CustomersLayout/CustomersLayout';
import CustomersSearch from '../pages/CustomersSearch/CustomersSearch';
import SearchResultsTable from '../pages/SearchResultsTable/SearchResultsTable';
import CustomerProfile from '../pages/CustomerProfile/CustomerProfile';
import Events from '../pages/CustomerProfile/Events/Events';
import JourneyPage from '../pages/JourneyPage/JourneyPage';
import EmailPage from '../pages/CustomerProfile/Comms/EmailPage';
import SmsPage from '../pages/CustomerProfile/Comms/SmsPage';
import Users from '../pages/Users/Users';
import TemplatePage from '../pages/TemplatePage/TemplatePage';
import Profile from "../pages/Profile/Profile";
import NotificationPage from "../pages/NotificationPage/NotificationPage";

const authRequiredRoutes = (theme: string, setTheme: any) => {
  return (
    <>
      <Route path={'dashboards'}>
        <Route index element={<Dashboard theme={theme} setTheme={setTheme} />} />
        <Route path={'leads'} element={<ReportTable title={'Leads.'} reportType={'lead'} />} />
        <Route
          path={'applications'}
          element={<ReportTable title={'Apps.'} reportType={'application'} />}
        />
        <Route
          path={'successes'}
          element={<ReportTable title={'Success.'} reportType={'application'} />}
        />
        <Route
          path={'paid-out'}
          element={<ReportTable title={'Paid out.'} reportType={'furniture'} />}
        />
        <Route
          path={'introduced'}
          element={<ReportTable title={'Introduced.'} reportType={'introduced'} />}
        />
      </Route>
      <Route path={'customers'} element={<CustomersLayout />}>
        <Route index element={<CustomersSearch />} />
        <Route path={'search'} element={<SearchResultsTable />} />
        <Route path={':id'} element={<CustomerProfile />} />
        <Route path={':id/events'} element={<Events />} />
        <Route path={':id/journey'} element={<JourneyPage />} />
        <Route path={':id/email'} element={<EmailPage />} />
        <Route path={':id/sms'} element={<SmsPage />} />
      </Route>
      <Route path={'settings'}>
        <Route index element={<Profile/>}/>
        <Route path={'users'} element={<Users />} />
        <Route path={'templates'} element={<TemplatePage />} />
      </Route>
          <Route path={'notifications'} element={<NotificationPage/>}/>
    </>
  );
};
export default authRequiredRoutes;
