import React from 'react';
import * as Yup from 'yup';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import ChainedModal from '../../ChainedModal/ChainedModal';
import DialogPopupWrapper from '../../DialogPopup/DialogPopup';
import BtnGroup from '../../BtnGroup/BtnGroup';
import Input from '../../input/Input';
import loader from '../../../assets/loader.gif';
import Button from '../../Button/Button';
import '../DialogModal.scss';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import {
  setStatusChange,
  setRequestIsFinished,
} from '../../../app/features/commands/commandsSlice';
import CustomSelect, { ISelectOption } from '../../CustomSelect/CustomSelect';

const Modal1 = (props: any) => {
  return (
    <DialogPopupWrapper title={'Decline this Hire'} className={'modal'}>
      <div className="modal__body">
        <p className="modal__text">Are you sure you want to decline this hire?</p>
        <BtnGroup
          className="modal__btn"
          activeBtnName={'OK'}
          onCancel={() => props.closeChainedModal()}
          onClick={() => props.showNext()}
        />
      </div>
    </DialogPopupWrapper>
  );
};
const Modal2 = (props: any) => {
  const [selectedOption, setSelectedOption] = React.useState<any>();

  const reasonOptionsList: ISelectOption[] = [
    { value: 'budget shortfall', label: 'Budget shortfall' },
    { value: 'affordability', label: 'Affordability' },
    { value: 'credit risk', label: 'Credit risk' },
    { value: 'validation risk', label: 'Validation risk' },
    { value: 'other', label: 'Other' },
  ];

  const handleNext = () => {
    props.handleReason(selectedOption.value);
    props.showNext();
  };

  return (
    <DialogPopupWrapper title={'Decline reason'} className={'modal'}>
      <div className="modal__body">
        <CustomSelect
          label={'Please select reason'}
          onChange={setSelectedOption}
          optionsList={reasonOptionsList}
          selectedOption={selectedOption}
        />
        <BtnGroup
          className="modal__btn"
          activeBtnName={'OK'}
          onCancel={() => props.closeChainedModal()}
          onClick={handleNext}
        />
      </div>
    </DialogPopupWrapper>
  );
};
const Modal3 = (props: any) => {
  const dispatch = useAppDispatch();

  const { isModalLoading, isRequestFinished } = useAppSelector((state) => state.commands);

  React.useEffect(() => {
    if (isRequestFinished) {
      dispatch(setRequestIsFinished(false));
      props.showNext();
    }
  }, [isRequestFinished]);

  const schema = Yup.object().shape({
    password: Yup.string().required(),
  });

  const initialValues: { password: string } = {
    password: 'ZfgxcVMjoA',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: () => {},
  });

  return (
    <DialogPopupWrapper title={'Enter your password'} className={'modal'}>
      <div className="modal__body">
        <FormikProvider value={formik}>
          <Form className="" autoComplete="off">
            <Field
              className={'modal__input'}
              d="password"
              name="password"
              type="password"
              error={formik.errors.password && formik.touched.password && 'Password required'}
              as={Input}
            />
          </Form>
        </FormikProvider>
        {isModalLoading ? (
          <div className={'modal__loader'}>
            <img src={loader} alt="loader" />
          </div>
        ) : (
          <Button
            onClick={() =>
              dispatch(
                setStatusChange({
                  password: formik.values.password,
                  status: 'decline',
                  criteria: props.reason,
                })
              )
            }
            className={'modal__btn'}
          >
            OK
          </Button>
        )}
      </div>
    </DialogPopupWrapper>
  );
};
const Modal4 = (props: any) => {
  const { isModalSuccess } = useAppSelector((state) => state.commands);

  return (
    <DialogPopupWrapper
      title={isModalSuccess ? 'Hire declined' : 'Hire not declined'}
      className={'modal'}
    >
      <div className="modal__body">
        <div />
        <Button onClick={() => props.closeChainedModal()} className={'modal__btn'}>
          OK
        </Button>
      </div>
    </DialogPopupWrapper>
  );
};

const DeclineChainModal = ({ onClose, visible }: any) => {
  const [reason, setReason] = React.useState('');

  const handleReason = (reason: string) => {
    setReason(reason);
  };

  return (
    <ChainedModal
      visible={visible}
      onRequestClose={onClose}
      closeOnBackground
      elements={[
        {
          component: Modal1,
        },
        {
          component: Modal2,
          props: { handleReason },
        },
        {
          component: Modal3,
          props: { reason },
        },
        {
          component: Modal4,
        },
      ]}
    />
  );
};

export default DeclineChainModal;
