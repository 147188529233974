//@ts-nocheck
import { EditorContent, useEditor, Editor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import TextStyle from '@tiptap/extension-text-style';
import Link from '@tiptap/extension-link';
import React, { useImperativeHandle } from 'react';
import { TextStyleExtended } from './extension/FontSize';
import './TextEditor.scss';
import { Color } from '@tiptap/extension-color';

const CustomLink = Link.extend({
  addKeyboardShortcuts() {
    return {
      'Mod-k': () => {
        const previousUrl = this.editor.getAttributes('link').href;
        const url = window.prompt('URL', previousUrl);

        // cancelled
        if (url === null) {
          return;
        }

        // empty
        if (url === '') {
          this.editor.chain().focus().extendMarkRange('link').unsetLink().run();
          return;
        }

        // update link
        this.editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
      },
    };
  },
});

const TextEditor = React.forwardRef(
  ({ content, label, onChange, setContent, output = 'html', className }: any, ref) => {
    const editor = useEditor({
      extensions: [
        StarterKit,
        TextStyle,
        TextStyleExtended,
        CustomLink,
        Color,
        Link.configure({
          openOnClick: true,
          HTMLAttributes: {
            target: '_blank',
          },
        }),
      ],
      onUpdate: ({ editor }) => {
        const outputValue =
          output === 'html'
            ? editor.getHTML()
            : output === 'json'
              ? editor.getJSON()
              : editor.getText();
        onChange(outputValue);
      },
      content,
    });

    const editorRef: React.MutableRefObject<Editor | null> = React.useRef(null);
    useImperativeHandle(ref, () => ({
      clearContent: () => {
        editorRef.current?.commands.clearContent();
      },
      insertContent: (content: string) => {
        editorRef.current?.commands.insertContent(content);
      },
      deleteRange: (from: number, to: number) => {
        editorRef.current?.commands.deleteRange({ from, to });
      },
      getCharacterCount: () => editorRef.current?.getCharacterCount(),
      insertContentAt: (position: number, content: string) => {
        editorRef.current?.commands.insertContentAt(position, content);
      },
      getHTML: () => {
        return editorRef.current?.getHTML();
      },
      getJSON: () => {
        return editorRef.current?.getJSON();
      },
      getText: () => {
        return editorRef.current?.getText();
      },
    }));

    if (!editor) return null;
    editorRef.current = editor;

    return (
      <div className={`editor ${className}`}>
        {label && <label className={'editor__label'}>{label}</label>}
        <EditorContent
          ref={ref}
          editor={editor}
        />
      </div>
    );
  }
);

export default TextEditor;
