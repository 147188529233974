import React from 'react';
import Card from '../../Card/Card';
import './CardContainer.scss';

export interface ICardContainer {
  comment: string;
  onClick?: () => void;
  className?: string;
  children: any;
  size?: 'lg' | 'sm' | 'full';
}

const CardContainer = ({ onClick, comment, children, className, size = 'lg' }: ICardContainer) => {
  return (
    <Card className={`card-modal ${className}`} color="light" size={size} onClick={onClick}>
      <span className="card-modal__comment">{comment}</span>
      {children}
    </Card>
  );
};

export default CardContainer;
