import CustomAxios from '../../customAxios/CustomAxios';

export const getAllEmailsAPI = () =>
  CustomAxios.get(`${process.env.REACT_APP_TEMPLATES_URL}/templates/emails`);
export const getAllSmsAPI = () =>
  CustomAxios.get(`${process.env.REACT_APP_TEMPLATES_URL}/templates/sms`);
export const createEmailTemplateAPI = (data: { name: string; content: { [key: string]: any } }) => {
  return CustomAxios.post(`${process.env.REACT_APP_TEMPLATES_URL}/templates/emails`, data);
};
export const createSmsTemplateAPI = (data: { name: string; content: string }) => {
  return CustomAxios.post(`${process.env.REACT_APP_TEMPLATES_URL}/templates/sms`, data);
};
export const updateSmsTemplateAPI = ({ id, content }: { id: string; content: string }) => {
  return CustomAxios.put(`${process.env.REACT_APP_TEMPLATES_URL}/templates/sms/${id}}`, content);
};
export const updateEmailTemplateAPI = ({id, content}: { id: string; content: { [key: string]: any } }) => {
  return CustomAxios.put(`${process.env.REACT_APP_TEMPLATES_URL}/templates/emails/${id}`, {content});
};
export const deleteSmsTemplateAPI = (id: number) =>
  CustomAxios.delete(`${process.env.REACT_APP_TEMPLATES_URL}/templates/sms/${id}`);
export const deleteEmailTemplateAPI = (id: number) =>
  CustomAxios.delete(`${process.env.REACT_APP_TEMPLATES_URL}/templates/emails/${id}`);
