import React, { useState } from 'react';
import { Outlet, useMatch, useParams } from 'react-router-dom';
import Header from '../profileComponents/Header/Header';
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { getCustomerById } from '../../app/features/customers/customersSlice';
import Loader from '../Loader/Loader';
import PayoutChainModal from '../DialogModals/PayoutModal/PayoutModal';
import NotTakenUpChainModal from '../DialogModals/NotTakenUpModal/NotTakenUpModal';
import DeclineChainModal from '../DialogModals/DeclineModal/DeclineModal';
import DoNotLendChainModal from '../DialogModals/DoNotLendModal/DoNotLendModal';
import ShareChainModal from '../DialogModals/ShareModal/ShareModal';

const CustomersLayout = () => {
  const { id } = useParams();

  let journey = useMatch({ path: 'customers/:id/journey', end: true });
  let search = useMatch({ path: 'customers', end: true });
  let results = useMatch({ path: 'customers/search', end: true });

  const dispatch = useAppDispatch();

  const { isLoading, customer, reports } = useAppSelector((state) => state.customers);

  const [showModal, setShowModal] = React.useState<number>(0);
  const [agentView, setAgentView] = useState<boolean>(false);

  React.useEffect(() => {
    if (id && id !== customer.userId && search === null) {
      dispatch(getCustomerById(id));
    }
  }, [dispatch, id]);

  const handleChangeProfileView = () => {
    setAgentView(!agentView);
  };

  if (isLoading) {
    return <Loader />;
  }

  const handleSelectedModal = (id: number) => {
    setShowModal(id);
  };

  return (
    <>
      {!journey && !search && !results && (
        <Header
          title={customer.title}
          agentView={agentView}
          fullName={`${customer.firstName} ${customer.lastName}`}
          handleChangeProfileView={handleChangeProfileView}
          setShowModal={handleSelectedModal}
        />
      )}
      {/*{customer.id && <Outlet context={[customer, reports, agentView]}/>}*/}
      <Outlet context={[customer, reports, agentView]} />
      <PayoutChainModal visible={showModal === 1} onClose={() => setShowModal(0)} />
      <DeclineChainModal visible={showModal === 2} onClose={() => setShowModal(0)} />
      <DoNotLendChainModal visible={showModal === 3} onClose={() => setShowModal(0)} />
      <NotTakenUpChainModal visible={showModal === 4} onClose={() => setShowModal(0)} />
      <ShareChainModal visible={showModal === 5} onClose={() => setShowModal(0)} />
    </>
  );
};

export default React.memo(CustomersLayout);
