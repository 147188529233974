import React from 'react';
import * as Yup from 'yup';
import { Field, Form, FormikProvider, useFormik } from 'formik';
import ChainedModal from '../../ChainedModal/ChainedModal';
import DialogPopupWrapper from '../../DialogPopup/DialogPopup';
import BtnGroup from '../../BtnGroup/BtnGroup';
import Input from '../../input/Input';
import loader from '../../../assets/loader.gif';
import Button from '../../Button/Button';
import '../DialogModal.scss';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import { setStatusChange, setRequestIsFinished } from '../../../app/features/commands/commandsSlice';

const Modal1 = (props: any) => {
  return (
    <DialogPopupWrapper title={'Not taken up'} className={'modal'}>
      <div className="modal__body">
        <p className="modal__text">
          Are you sure you want to flag this person as having “not taken up” their loan?
        </p>
        <BtnGroup
          className="modal__btn"
          activeBtnName={'OK'}
          onCancel={() => props.closeChainedModal()}
          onClick={() => props.showNext()}
        />
      </div>
    </DialogPopupWrapper>
  );
};
const Modal2 = (props: any) => {
  const dispatch = useAppDispatch();

  const { isModalLoading, isRequestFinished } = useAppSelector((state) => state.commands);

  React.useEffect(() => {
    if (isRequestFinished) {
      dispatch(setRequestIsFinished(false));
      props.showNext();
    }
  }, [isRequestFinished]);

  const schema = Yup.object().shape({
    password: Yup.string().required(),
  });

  const initialValues: { password: string } = {
    password: '',
  };

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: () => {},
  });

  return (
    <DialogPopupWrapper title={'Enter your password'} className={'modal'}>
      <div className="modal__body">
        <FormikProvider value={formik}>
          <Form className="" autoComplete="off">
            <Field
              className={'modal__input'}
              d="password"
              name="password"
              type="password"
              error={formik.errors.password && formik.touched.password && 'Password required'}
              as={Input}
            />
          </Form>
        </FormikProvider>
        {isModalLoading ? (
          <div className={'modal__loader'}>
            <img src={loader} alt="loader" />
          </div>
        ) : (
          <Button onClick={() => dispatch(setStatusChange({password: formik.values.password, status: 'not taken up'}))} className={'modal__btn'}>
            OK
          </Button>
        )}
      </div>
    </DialogPopupWrapper>
  );
};
const Modal3 = (props: any) => {
  const { isModalSuccess } = useAppSelector((state) => state.commands);

  return (
    <DialogPopupWrapper
      title={isModalSuccess ? 'Flagged as “not taken up”' : 'Not flagged as “not taken up”'}
      className={'modal'}
    >
      <div className="modal__body">
        <div />
        <Button onClick={() => props.closeChainedModal()} className={'modal__btn'}>
          OK
        </Button>
      </div>
    </DialogPopupWrapper>
  );
};

const NotTakenUpChainModal = ({ onClose, visible }: any) => {
  return (
    <ChainedModal
      visible={visible}
      onRequestClose={onClose}
      closeOnBackground
      elements={[
        {
          component: Modal1,
        },
        {
          component: Modal2,
        },
        {
          component: Modal3,
        },
      ]}
    />
  );
};

export default NotTakenUpChainModal;
