export const changeFavIcon = (value: boolean) => {
  const favicon = document.getElementById('favicon') as HTMLAnchorElement | null;
  if (favicon !== null) {
    if (value) {
      favicon.href = 'faviconNotification.png';
    } else {
      favicon.href = 'favicon.png';
    }
  }
};
