import React from 'react';
import ChainedModal from '../../ChainedModal/ChainedModal';
import DialogPopupWrapper from '../../DialogPopup/DialogPopup';
import BtnGroup from '../../BtnGroup/BtnGroup';
import Button from '../../Button/Button';
import '../DialogModal.scss';
import { useAppDispatch, useAppSelector } from '../../../app/hooks';
import CustomSelect, { ISelectOption } from '../../CustomSelect/CustomSelect';
import { getUsers } from '../../../app/features/users/usersSlice';
import { shareCustomer } from '../../../app/features/commands/commandsSlice';

const Modal1 = (props: any) => {
  const dispatch = useAppDispatch();

  const { users } = useAppSelector((state) => state.users);
  const { isLoading, isModalSuccess } = useAppSelector((state) => state.commands);
  const [usersList, setUsers] = React.useState<any>([]);
  const [selectedUser, setUser] = React.useState<any>(null);

  React.useEffect(() => {
    dispatch(getUsers());
  }, []);

  React.useEffect(() => {
    const newUsersList: ISelectOption[] = users.map((item) => {
      return {
        value: item.id,
        label: item.fullName,
      };
    });

    setUsers(newUsersList);
  }, [users]);

  const handleClick = () => {
    dispatch(shareCustomer(selectedUser.value));
    if (!isLoading && isModalSuccess) {
      props.showNext()

    }
  };

  return (
    <DialogPopupWrapper title={'Share'} className={'modal'}>
      <div className="modal__body">
        <CustomSelect
          label={'Share with'}
          optionsList={usersList}
          placeholder={'share with'}
          selectedOption={selectedUser}
          onChange={setUser}
        />
        <BtnGroup
          className="modal__btn"
          activeBtnName={'OK'}
          onCancel={() => props.closeChainedModal()}
          onClick={handleClick}
        />
      </div>
    </DialogPopupWrapper>
  );
};
const Modal2 = (props: any) => {
  return (
    <DialogPopupWrapper title={'Share'} className={'modal'}>
      <div className="modal__body">
        <p className="modal__text">Customer shared.</p>
        <Button onClick={() => props.closeChainedModal()} className={'modal__btn'}>
          OK
        </Button>
      </div>
    </DialogPopupWrapper>
  );
};

const ShareChainModal = ({ onClose, visible }: any) => {
  return (
    <ChainedModal
      visible={visible}
      onRequestClose={onClose}
      closeOnBackground
      elements={[
        {
          component: Modal1,
        },
        {
          component: Modal2,
        },
      ]}
    />
  );
};

export default ShareChainModal;
