import React from 'react';
import Search from '../../components/Search/Search';
import './CustomersSearch.scss';

const CustomersSearch = () => {
  return (
    <div className="customers-search">
      <div className="customers-search__container">
        <Search />
      </div>
    </div>
  );
};

export default CustomersSearch;
